var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title:
              _vm.status == "create" ? "Thêm trường học" : "Cập nhật trường",
            visible: _vm.visible,
            width: _vm.modalWidth,
            top: "30px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.tabName,
                callback: function ($$v) {
                  _vm.tabName = $$v
                },
                expression: "tabName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "Thông tin", name: "info" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        rules: _vm.rules,
                        model: _vm.form,
                        "label-position": "top",
                        "label-width": "80px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Tên", prop: "name" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: _vm.form.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "name", $$v)
                                      },
                                      expression: "form.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "Mã", prop: "code" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: _vm.form.code,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "code", $$v)
                                      },
                                      expression: "form.code",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Tỉnh thành",
                                    prop: "addressCityId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        placeholder: "",
                                      },
                                      model: {
                                        value: _vm.form.addressCityId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "addressCityId",
                                            $$v
                                          )
                                        },
                                        expression: "form.addressCityId",
                                      },
                                    },
                                    _vm._l(_vm.cities, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Quận huyện",
                                    prop: "addressDistrictId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        placeholder: "",
                                      },
                                      model: {
                                        value: _vm.form.addressDistrictId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "addressDistrictId",
                                            $$v
                                          )
                                        },
                                        expression: "form.addressDistrictId",
                                      },
                                    },
                                    _vm._l(_vm.districts, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.nameWithType,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "Mật khẩu đầu tiên" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      disabled: "",
                                      value: _vm.form.isChangedDefaultPassword
                                        ? "Đã đổi mật khẩu"
                                        : _vm.form.firstPassword,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Địa chỉ" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: _vm.form.address,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "address", $$v)
                                      },
                                      expression: "form.address",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "Email" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: _vm.form.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "email", $$v)
                                      },
                                      expression: "form.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Phòng GD&DT",
                                    prop: "divisionId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        disabled: !!_vm.divisionId,
                                        placeholder: "",
                                      },
                                      model: {
                                        value: _vm.form.divisionId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "divisionId", $$v)
                                        },
                                        expression: "form.divisionId",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: "Không chọn",
                                          value: 0,
                                        },
                                      }),
                                      _vm._l(_vm.divisions, function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        })
                                      }),
                                    ],
                                    2
                                  ),
                                  _c("el-button", {
                                    staticClass: "btn-add-in-select",
                                    attrs: {
                                      type: "primary",
                                      size: "mini",
                                      icon: "el-icon-plus",
                                    },
                                    on: { click: _vm.handleCreateDivision },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Khối", prop: "gradeIds" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        placeholder: "",
                                        multiple: "",
                                      },
                                      model: {
                                        value: _vm.form.gradeIds,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "gradeIds", $$v)
                                        },
                                        expression: "form.gradeIds",
                                      },
                                    },
                                    _vm._l(_vm.grades, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Khối đặt sách",
                                    prop: "orderGradeIds",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        placeholder: "",
                                        multiple: "",
                                      },
                                      model: {
                                        value: _vm.form.orderGradeIds,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "orderGradeIds",
                                            $$v
                                          )
                                        },
                                        expression: "form.orderGradeIds",
                                      },
                                    },
                                    _vm._l(_vm.grades, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.status != "create"
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "Lớp học", name: "class-room" } },
                    [_c("ClassRoomTab")],
                    1
                  )
                : _vm._e(),
              _vm.status != "create"
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "Giáo viên", name: "teacher" } },
                    [_c("TeacherTab")],
                    1
                  )
                : _vm._e(),
              _vm.status != "create"
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "Sổ liên lạc", name: "contact-book" } },
                    [_c("ContactBookTab")],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm.tabName == "info"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function ($event) {
                          _vm.status == "create"
                            ? _vm.createData()
                            : _vm.updateData()
                        },
                      },
                    },
                    [_vm._v("Đồng ý")]
                  )
                : _vm._e(),
              _vm.tabName == "commission"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: { click: _vm.updateCommission },
                    },
                    [_vm._v("Cập nhật hoa hồng")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("DivisionModal", {
        ref: "division-modal",
        on: { "submit:success": _vm.fetchDivision },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }