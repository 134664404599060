"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tagOptions = exports.VehicleType = exports.TransactionType = exports.TYPE_CONTENT_DEFINE = exports.STORE_TYPE = exports.PreOrderDeliveryStatus = exports.PaymentStatus = exports.PARAMS = exports.OrderStatusNew = exports.OrderStatus = exports.OrderKvStatusNew = exports.ORDER_TYPE = exports.ORDER_TRANSPORT_STATUS = exports.ORDER_FOOD_STATUS = exports.ORDER_DELIVERY_STATUS = exports.NewsStoreType = exports.CustomerCategory = exports.CoverType = exports.CategoryFood = exports.BookTypeTrans = exports.BookType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var STORE_TYPE = exports.STORE_TYPE = {
  FOOD: "Thức ăn",
  DRINK: "Thức uống"
};
var ORDER_FOOD_STATUS = exports.ORDER_FOOD_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ",
  FIND_DRIVER: "Đang tìm tài xế",
  ACCEPT_ORDER: "Chấp nhận đơn",
  DRIVER_CANCEL: "Tài xế huỷ",
  CUSTOMER_CANCEL: "Khách huỷ",
  NOT_FOUND_DRIVER: "Không có tài xế"
};
var ORDER_DELIVERY_STATUS = exports.ORDER_DELIVERY_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ",
  FIND_DRIVER: "Đang tìm tài xế",
  ACCEPT_ORDER: "Chấp nhận đơn",
  DRIVER_CANCEL: "Tài xế huỷ",
  CUSTOMER_CANCEL: "Khách huỷ",
  NOT_FOUND_DRIVER: "Không có tài xế"
};
var ORDER_TRANSPORT_STATUS = exports.ORDER_TRANSPORT_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ",
  FIND_DRIVER: "Đang tìm tài xế",
  ACCEPT_ORDER: "Chấp nhận đơn",
  DRIVER_CANCEL: "Tài xế huỷ",
  NOT_FOUND_DRIVER: "Không có tài xế",
  CUSTOMER_CANCEL: "Khách huỷ"
};
var TYPE_CONTENT_DEFINE = exports.TYPE_CONTENT_DEFINE = {
  ABOUT: "Thông tin chúng tôi",
  FAQ: "Câu hỏi thường gặp",
  HOW_TO_USE: "Cách sử dụng",
  HOW_TO_USE_TEACHER: "Cách sử dụng",
  SECURITY: "Bảo mật",
  TERM_CONDITION: "Điều khoản",
  NOTE_PRINT_TICKET: "Ghi chú đặt sách",
  PAYMENT_METHOD: "Phương thức thanh toán",
  TERM_EXCHANGE_REFUND: "Chính sách đổi -  trả - hoàn tiền",
  DELIVERY_METHOD: "Phương thức vận chuyển"
};
var ORDER_TYPE = exports.ORDER_TYPE = {
  FOOD: "Thức ăn",
  TRANSPORT: "Di chuyển xe máy",
  DELIVERY: "Giao hàng",
  TRANSPORT_CAR_7: "Di chuyển ô tô 7 chỗ",
  TRANSPORT_CAR_4: "Di chuyển ô tô 4 chỗ"
};
var PARAMS = exports.PARAMS = {
  COMMISSION_FOOD: "Thức ăn",
  COMMISSION_DELIVERY: "Giao hàng",
  COMMISSION_TRANSPORT: "Di chuyển ",
  MIN_DRIVER_BALANCE: "Tài khoản tối thiểu",
  DISTANCE_FIND_DRIVER: "Khoảng cách tìm tài xế",
  RATE_POINT: "Tỷ lệ quy đổi",
  REWARD_RATE_STORE: "Thưởng đánh giá cửa hàng",
  REWARD_RATE_ORDER: "Thưởng đánh giá đơn hàng"
};
var tagOptions = exports.tagOptions = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
  NOT_FOUND_DRIVER: {
    effect: "plain",
    type: "danger"
  },
  WAITING: {
    type: "warning",
    effect: "plain"
  },
  ACCEPT_ORDER: {
    type: "warning",
    effect: "plain"
  },
  DELIVERING: {
    type: "",
    effect: "plain"
  },
  DRIVER_CANCEL: {
    type: "danger",
    effect: "plain"
  },
  COMPLETE: {
    type: "success",
    effect: "dark"
  }
}, "NOT_FOUND_DRIVER", {
  type: "danger",
  effect: "plain"
}), "CUSTOMER_CANCEL", {
  type: "danger",
  effect: "dark"
}), "FIND_DRIVER", {
  type: "danger",
  effect: "dark"
}), "CANCEL", {
  type: "danger",
  effect: "dark"
});
var VehicleType = exports.VehicleType = {
  BIKE: "Môtô 2 bánh",
  CAR_4: "Ôtô 4 bánh",
  CAR_7: "Ôtô 7 bánh"
};
var NewsStoreType = exports.NewsStoreType = {
  Plus: "PLUS",
  Coffee: "COFFEE"
};
var TransactionType = exports.TransactionType = {
  Deposit: "DEPOSIT",
  Withdraw: "WITHDRAW",
  Income: "INCOME"
};
var CategoryFood = exports.CategoryFood = {
  VEGETARIAN: "Món chay",
  SNACK: "Ăn vặt",
  RICE: "Cơm",
  MILK_TEA: "Trà sữa"
};
var CustomerCategory = exports.CustomerCategory = {
  ALL: "Tất cả",
  BIRTHDAY_IN_MONTH: "Sinh nhật trong tháng",
  OFFLINE: "Không hoạt động",
  FEMALE: "Nữ",
  BADGE: "Cấp bậc"
};
var CoverType = exports.CoverType = {
  HARD: "Bìa cứng",
  SOFT: "Bìa mềm"
};
var PreOrderDeliveryStatus = exports.PreOrderDeliveryStatus = {
  PENDING: "Chờ đóng gói",
  DELIVERING: "Đang vận chuyển",
  COMPLETE: "Đã giao"
};
var OrderStatus = exports.OrderStatus = {
  PENDING: "Chờ xác nhận",
  PACKAGE: "Đóng gói",
  DELIVERING: "Đang vận chuyển",
  COMPLETE: "Đã giao",
  CANCEL: "Huỷ"
};
var OrderStatusNew = exports.OrderStatusNew = {
  1: "Phiếu tạm",
  2: "Đang giao hàng",
  3: "Hoàn thành",
  4: "Đã huỷ",
  5: "Đã xác nhận"
};
var OrderKvStatusNew = exports.OrderKvStatusNew = {
  1: OrderStatus.PENDING,
  2: OrderStatus.DELIVERING,
  3: OrderStatus.COMPLETE,
  4: OrderStatus.CANCEL,
  5: OrderStatus.PACKAGE
};
var BookType = exports.BookType = {
  Single: "SINGLE",
  Combo: "COMBO",
  Clothing: "CLOTHING"
};
var BookTypeTrans = exports.BookTypeTrans = {
  SINGLE: "Sản phẩm đơn",
  COMBO: "Sản phẩm combo",
  CLOTHING: "Đồng phục"
};
var PaymentStatus = exports.PaymentStatus = {
  PENDING: "Đang chờ thanh toán",
  FAIL: "Thất bại",
  COMPLETE: "Hoàn thành",
  REFUND: "Hoàn tiền"
};