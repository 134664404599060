"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/toConsumableArray.js"));
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _HistorySubmitDivision = _interopRequireDefault(require("./HistorySubmitDivision.vue"));
var _SummaryDivision = _interopRequireDefault(require("./SummaryDivision.vue"));
var _Export2Excel = require("@/vendor/Export2Excel");
var _SummaryGrade = _interopRequireDefault(require("./SummaryGrade.vue"));
var _default = exports.default = {
  components: {
    HistorySubmitDivision: _HistorySubmitDivision.default,
    SummaryDivision: _SummaryDivision.default,
    SummaryGrade: _SummaryGrade.default
  },
  data: function data() {
    return {
      visible: false,
      data: {},
      division: {},
      summaryBook: [],
      divisionSubmitPreOrderDetails: [],
      tabName: "general"
    };
  },
  computed: {
    totalCommission: function totalCommission() {
      return this.divisionSubmitPreOrderDetails.reduce(function (prev, cur) {
        return prev + cur.commission;
      }, 0);
    }
  },
  methods: {
    handleExport: function handleExport() {
      var header = ["Sách", "Đơn giá", "SL", "Thành tiền", "Hoa hồng"];
      var total = {
        quantity: 0,
        commission: 0,
        amount: 0
      };
      var data = [];
      this.divisionSubmitPreOrderDetails.forEach(function (element) {
        total.quantity += element.quantity;
        total.amount += element.quantity * element.finalPrice;
        total.commission += element.commission;
        data.push([element.book.name, element.finalPrice, element.quantity, element.finalPrice * element.quantity, element.commission]);
      });
      data.push(["Tổng", "", total.quantity, total.amount, total.commission]);
      (0, _Export2Excel.export_json_to_excel)({
        data: data,
        header: header,
        filename: "\u0110\u1EE3t \u0111\u1EB7t s\xE1ch - ".concat(this.division.name)
      });
    },
    handleDetail: function handleDetail(data, division) {
      var _this = this;
      this.division = division;
      this.visible = true;
      this.data = (0, _objectSpread2.default)({}, data);
      this.summaryBook = [];
      this.divisionSubmitPreOrderDetails = data.submit.divisionSubmitPreOrderDetails;
      this.data.details.forEach(function (element) {
        _this.summaryBook = [].concat((0, _toConsumableArray2.default)(_this.summaryBook), (0, _toConsumableArray2.default)(element.schoolSubmitPreOrderDetails));
      });
    }
  }
};