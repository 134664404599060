"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _BarChart = _interopRequireDefault(require("../BarChart.vue"));
var _trackingCustomer = require("@/api/tracking-customer");
var _moment = _interopRequireDefault(require("moment"));
var _variables = _interopRequireDefault(require("@/styles/variables.scss"));
var _default = exports.default = {
  components: {
    BarChart: _BarChart.default
  },
  data: function data() {
    return {
      series: [],
      date: [(0, _moment.default)().startOf("month").format("YYYY-MM-DD"), (0, _moment.default)().endOf("month").format("YYYY-MM-DD")],
      dataChart: []
    };
  },
  created: function created() {
    this.getDataChart();
  },
  methods: {
    convertSeries: function convertSeries() {
      var _this = this;
      var columnSeri = {
        name: "Lượt truy cập",
        type: "bar",
        itemStyle: {
          color: _variables.default.primaryColor
        },
        yAxisIndex: 0,
        data: this.dataChart.map(function (item) {
          return item.total;
        })
      };
      this.series = [columnSeri];
      this.$nextTick(function () {
        _this.$refs.chart.initChart();
      });
    },
    getDataChart: function getDataChart() {
      var _this2 = this;
      var listQuery = {
        from: this.date[0],
        to: this.date[1]
      };
      _trackingCustomer.trackingCustomerApi.report(listQuery).then(function (res) {
        _this2.dataChart = res.data;
        _this2.convertSeries();
      });
    }
  }
};