"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.trackingCustomerApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var trackingCustomerApi = exports.trackingCustomerApi = {
  report: function report(params) {
    return (0, _request.default)({
      url: "/customerTracking/report",
      params: params
    });
  }
};