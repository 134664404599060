"use strict";

var _interopRequireWildcard = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var content = _interopRequireWildcard(require("@/api/content"));
var _date = require("@/utils/date");
var _format = require("@/utils/format");
var _enum = require("@/utils/enum");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])),
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      TYPE_CONTENT_DEFINE: _enum.TYPE_CONTENT_DEFINE,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      dialogLoading: false,
      submitLoading: false,
      formData: {},
      dialogVisible: false,
      dialogStatus: "create",
      tableData: [],
      rules: {
        title: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập tiêu đề"
        },
        type: {
          required: true,
          trigger: "submit",
          message: "Vui lòng chọn loại"
        },
        body: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập nội dung"
        }
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    onInitTiny: function onInitTiny() {
      this.$refs["editor"].setContent(this.formData.body);
    },
    handleDelete: function handleDelete(row) {
      var _this = this;
      this.$confirm("Nội dung này sẽ bị xóa?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Hủy",
        type: "warning"
      }).then(function () {
        content.remove(row.id).then(function () {
          _this.$notify({
            type: "success",
            message: "Xóa thành công"
          });
          _this.getList();
        });
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this2 = this;
      this.dialogVisible = true;
      this.dialogStatus = "update";
      this.$nextTick(function () {
        _this2.$refs["form"].clearValidate();
        _this2.formData = (0, _objectSpread2.default)({}, row);
        _this2.formData.start *= 1000;
        _this2.formData.end *= 1000;
        if (_this2.$refs["editor"]) {
          _this2.$refs["editor"].setContent(_this2.formData.body);
        }
      });
    },
    beforeUpload: function beforeUpload(file) {
      this.dialogLoading = true;
      var isJPG = file.type.includes("image");
      if (!isJPG) {
        this.dialogLoading = false;
        this.$message.error("Ảnh không đúng định dạng!");
      }
      return isJPG;
    },
    handleUploadSuccess: function handleUploadSuccess(res, file) {
      this.formData.image = res.data.path;
      this.dialogLoading = false;
    },
    handleCreate: function handleCreate() {
      var _this3 = this;
      this.dialogVisible = true;
      this.dialogStatus = "create";
      this.$nextTick(function () {
        _this3.$refs["form"].clearValidate();
        _this3.formData = {
          body: ""
        };
        if (_this3.$refs["editor"]) {
          _this3.$refs["editor"].setContent("");
        }
      });
    },
    updateData: function updateData() {
      var _this4 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this4.submitLoading = true;
          var data = {
            contentDefine: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this4.formData), {}, {
              body: (0, _utils.convertLinkYoutube)(_this4.formData.body)
            })
          };
          content.update(_this4.formData.id, data).then(function (res) {
            if (res.status) {
              _this4.$message({
                type: "success",
                message: "Cập nhật nội dung thành công"
              });
              _this4.getList();
              _this4.dialogVisible = false;
            }
          }).finally(function () {
            _this4.submitLoading = false;
          });
        }
      });
    },
    createData: function createData() {
      var _this5 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this5.submitLoading = true;
          var data = {
            contentDefine: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this5.formData), {}, {
              body: (0, _utils.convertLinkYoutube)(_this5.formData.body)
            })
          };
          content.create(data).then(function () {
            _this5.$message({
              type: "success",
              message: "Thêm nội dung thành công"
            });
            _this5.getList();
            _this5.dialogVisible = false;
          }).finally(function () {
            _this5.submitLoading = false;
          });
        }
      });
    },
    formatDate: function formatDate(n) {
      return (0, _date.formatDate)(n);
    },
    getList: function getList() {
      var _this6 = this;
      content.findAll().then(function (res) {
        _this6.tableData = res.data;
      });
    }
  }
};