"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["data"],
  methods: {
    getSummaries: function getSummaries(_ref) {
      var data = _ref.data;
      var sum = [];
      sum[0] = "Tổng";
      sum[2] = 0;
      sum[3] = 0;
      sum[4] = 0;
      sum[5] = 0;
      sum[6] = 0;
      sum[7] = 0;
      data.forEach(function (element) {
        sum[2] += element.quantity;
        sum[3] += element.quantity * element.finalPrice;
        sum[4] += element.quantityDivision;
        sum[5] += element.divisionCommission;
        sum[6] += element.quantityCompany;
        sum[7] += element.companyCommission;
      });
      this.$emit("onChangeTotalCommission", {
        total: sum[7] + sum[5],
        division: sum[5],
        company: sum[7]
      });
      sum[2] = this.$formatNumberVN(sum[2]);
      sum[3] = this.$formatNumberVN(sum[3]);
      sum[4] = this.$formatNumberVN(sum[4]);
      sum[5] = this.$formatNumberVN(sum[5]);
      sum[6] = this.$formatNumberVN(sum[6]);
      sum[7] = this.$formatNumberVN(sum[7]);
      return sum;
    }
  }
};