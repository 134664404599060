var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { span: _vm.numCol } },
            [_c("BookSummary")],
            1
          ),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { span: _vm.numCol } },
            [_c("OrderSummary")],
            1
          ),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { span: _vm.numCol } },
            [_c("SchoolSummary")],
            1
          ),
          _vm.numCol == 12
            ? _c(
                "el-col",
                { staticClass: "card-panel-col", attrs: { span: _vm.numCol } },
                [_c("PreOrderSummary", { on: { dataNull: _vm.onDataNull } })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }