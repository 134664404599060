"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.joinDivision = exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _JoinDivision = _interopRequireDefault(require("./JoinDivision"));
var _OutDivision = _interopRequireDefault(require("./OutDivision"));
var _All = _interopRequireDefault(require("./All"));
var _vuex = require("vuex");
var _ContactBook = _interopRequireDefault(require("./ContactBook.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var joinDivision = exports.joinDivision = {
  In: "IN",
  Out: "OUT",
  All: "ALL"
};
var _default = exports.default = {
  components: {
    All: _All.default,
    JoinDivision: _JoinDivision.default,
    OutDivision: _OutDivision.default,
    ContactBook: _ContactBook.default
  },
  created: function created() {
    this.$store.dispatch("address/fetchCities");
  }
};