"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    summaryBook: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  methods: {
    getSummaries: function getSummaries(_ref) {
      var data = _ref.data;
      var sum = [];
      sum[0] = "Tổng";
      sum[2] = 0;
      sum[3] = 0;
      sum[4] = 0;
      data.forEach(function (element) {
        sum[2] += element.quantity;
        sum[3] += element.quantity * element.finalPrice;
        sum[4] += element.commission;
      });
      sum[2] = this.$formatNumberVN(sum[2]);
      sum[3] = this.$formatNumberVN(sum[3]);
      sum[4] = this.$formatNumberVN(sum[4]);
      return sum;
    }
  }
};