"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addressApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var addressApi = exports.addressApi = {
  findCity: function findCity(params) {
    return (0, _request.default)({
      url: "/addressCity",
      params: params
    });
  },
  updateCity: function updateCity(id, data) {
    return (0, _request.default)({
      url: "/addressCity/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  updateDistrict: function updateDistrict(id, data) {
    return (0, _request.default)({
      url: "/addressDistrict/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  findDistrict: function findDistrict(params) {
    return (0, _request.default)({
      url: "/addressDistrict",
      params: params
    });
  },
  findWard: function findWard(params) {
    return (0, _request.default)({
      url: "/addressWard",
      params: params
    });
  }
};