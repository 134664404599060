"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.deletes = deletes;
exports.findAll = findAll;
exports.findAllRecycle = findAllRecycle;
exports.remove = remove;
exports.removes = removes;
exports.restores = restores;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
function findAll(listQuery) {
  return (0, _request.default)({
    url: "/news",
    params: listQuery
  });
}
function findAllRecycle(listQuery) {
  return (0, _request.default)({
    url: "/news/recycle",
    params: listQuery
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/news",
    method: "post",
    data: data
  });
}
function update(newsId, data) {
  return (0, _request.default)({
    url: "/news/".concat(newsId, "/update"),
    method: "post",
    data: data
  });
}
function remove(newsId) {
  return (0, _request.default)({
    url: "/news/".concat(newsId, "/delete"),
    method: "post"
  });
}
function removes(data) {
  return (0, _request.default)({
    url: "/news/remove",
    method: "post",
    data: data
  });
}
function deletes(data) {
  return (0, _request.default)({
    url: "/news/delete",
    method: "post",
    data: data
  });
}
function restores(data) {
  return (0, _request.default)({
    url: "/news/restore",
    method: "post",
    data: data
  });
}