"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PartnerRegistrationFormStatusTrans = exports.PartnerRegistrationFormStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var PartnerRegistrationFormStatus;
(function (PartnerRegistrationFormStatus) {
  PartnerRegistrationFormStatus["New"] = "NEW";
  PartnerRegistrationFormStatus["Approve"] = "APPROVE";
  PartnerRegistrationFormStatus["Reject"] = "REJECT"; //Từ chối
})(PartnerRegistrationFormStatus || (exports.PartnerRegistrationFormStatus = PartnerRegistrationFormStatus = {}));
var PartnerRegistrationFormStatusTrans = exports.PartnerRegistrationFormStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, PartnerRegistrationFormStatus.New, 'Mới'), PartnerRegistrationFormStatus.Approve, 'Đã duyệt'), PartnerRegistrationFormStatus.Reject, 'Đã từ chối');