"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WithdrawStatusTrans = exports.WithdrawStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var WithdrawStatus;
(function (WithdrawStatus) {
  WithdrawStatus["New"] = "NEW";
  WithdrawStatus["Approve"] = "APPROVE";
  WithdrawStatus["Reject"] = "REJECT";
})(WithdrawStatus || (exports.WithdrawStatus = WithdrawStatus = {}));
var WithdrawStatusTrans = exports.WithdrawStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, WithdrawStatus.New, 'Mới'), WithdrawStatus.Approve, 'Đã duyệt'), WithdrawStatus.Reject, 'Bị từ chối');