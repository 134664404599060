"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _ContactBook = _interopRequireDefault(require("../ContactBook.vue"));
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    ContactBook: _ContactBook.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    school: function school(state) {
      return state.school.selected;
    }
  }))
};