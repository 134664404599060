"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _classRoom = _interopRequireDefault(require("@/views/class-room"));
var _vuex = require("vuex");
//
//
//
//
//
//
var _default = exports.default = {
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    school: function school(state) {
      return state.school.selected;
    }
  })),
  components: {
    ClassRoom: _classRoom.default
  }
};