var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "food-dialog",
      attrs: {
        "close-on-click-modal": false,
        top: "30px",
        title: _vm.dialogStatus == "add" ? "Thêm món ăn" : "Cập nhật món ăn",
        visible: _vm.dialogVisible,
        width: "550px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                disabled: _vm.dialogDisabled,
                rules: _vm.rules,
                model: _vm.tempData,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tên món", prop: "name" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.tempData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempData, "name", $$v)
                              },
                              expression: "tempData.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Giá gốc", prop: "originPrice" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.tempData.originPrice,
                                expression: "tempData.originPrice",
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.tempData.originPrice },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.tempData,
                                  "originPrice",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Giá sau giảm giá",
                            prop: "finalPrice",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.tempData.finalPrice,
                                expression: "tempData.finalPrice",
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.tempData.finalPrice },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.tempData,
                                  "finalPrice",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tình trạng" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { filterable: "", placeholder: "" },
                              model: {
                                value: _vm.tempData.isOutStock,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tempData, "isOutStock", $$v)
                                },
                                expression: "tempData.isOutStock",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "Còn món", value: false },
                              }),
                              _c("el-option", {
                                attrs: { label: "Hết món", value: true },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Nhóm món ăn" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.tempData.category,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tempData, "category", $$v)
                                },
                                expression: "tempData.category",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: null } }, [
                                _vm._v("Không có"),
                              ]),
                              _vm._l(_vm.CategoryFood, function (item, key) {
                                return _c(
                                  "el-radio",
                                  { key: key, attrs: { label: key } },
                                  [_vm._v(_vm._s(item))]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "thumbnail" } },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                disabled: _vm.dialogDisabled,
                                name: "file",
                                accept: "image/*",
                                drag: "",
                                "before-upload": _vm.beforeUpload,
                                action: _vm.baseUrl + "/foods/upload",
                                "on-success": _vm.handleUploadSuccess,
                                "show-file-list": false,
                                headers: { token: _vm.token },
                              },
                            },
                            [
                              !_vm.tempData.thumbnail
                                ? [
                                    _c("i", { staticClass: "el-icon-upload" }),
                                    _c(
                                      "div",
                                      { staticClass: "el-upload__text" },
                                      [
                                        _vm._v(" Kéo thả vào đây hoặc "),
                                        _c("em", [
                                          _vm._v("click vào để upload"),
                                        ]),
                                      ]
                                    ),
                                  ]
                                : _c("img", {
                                    staticClass: "avatar",
                                    attrs: {
                                      src:
                                        _vm.baseUrlMedia +
                                        _vm.tempData.thumbnail,
                                      alt: "",
                                    },
                                  }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          !_vm.dialogDisabled
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }