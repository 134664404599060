"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
_vue.default.use(_vueRouter.default);

/* Layout */

/* Router Modules */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: "/redirect",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/redirect/index"));
      });
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/index"));
    });
  },
  hidden: true
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/auth-redirect"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/404"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/401"));
    });
  },
  hidden: true
}, {
  path: "/",
  redirect: "/dashboard",
  hidden: true
}, {
  path: "/dashboard",
  component: _layout.default,
  redirect: "/dashboard/index",
  permission: true,
  meta: {
    permission: true,
    title: "Dashboard",
    icon: "dashboard"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/dashboard/index"));
      });
    },
    name: "Dashboard",
    meta: {
      permission: true,
      title: "Dashboard"
    }
  }]
}, {
  path: "/media",
  component: _layout.default,
  redirect: "/media/banner",
  permission: true,
  meta: {
    title: "Truyền thông",
    permission: true,
    icon: "satellite-dish-solid"
  },
  children: [{
    path: "banner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/banner"));
      });
    },
    name: "media-banner",
    meta: {
      permission: true,
      title: "Banner"
    }
  }, {
    path: "banner-teacher",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/banner-teacher"));
      });
    },
    name: "media-banner-teach",
    meta: {
      permission: true,
      title: "Banner - G.viên"
    }
  }, {
    path: "banner-web",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/banner-web"));
      });
    },
    name: "banner-web",
    meta: {
      permission: true,
      title: "Banner trang chủ shop"
    }
  }, {
    path: "news",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/news"));
      });
    },
    name: "media-news",
    meta: {
      permission: true,
      title: "Tin tức"
    }
  }, {
    path: "notify",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/notification"));
      });
    },
    name: "media-notify",
    meta: {
      permission: true,
      title: "Thông báo chung"
    }
  }]
}, {
  path: "/media-store",
  component: _layout.default,
  redirect: "/media-store",
  permission: true,
  meta: {
    title: "Media",
    icon: "photo-video-solid",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/media"));
      });
    },
    name: "media",
    meta: {
      permission: true,
      title: "Media"
    }
  }]
}, {
  path: "/book",
  component: _layout.default,
  redirect: "/book/index",
  permission: true,
  meta: {
    permission: true,
    title: "Quản lý sản phẩm",
    icon: "book-solid"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/book"));
      });
    },
    name: "book-index",
    meta: {
      permission: true,
      title: "Tất cả sách",
      feature: "manage-book"
    }
  }, {
    path: "clothing-index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/clothing"));
      });
    },
    name: "clothing-index",
    meta: {
      permission: true,
      title: "Tất cả đồng phục",
      feature: "manage-clothing"
    }
  },
  // {
  //   path: "book-partner",
  //   component: () => import("@/views/book-partner"),
  //   name: "book-partner",
  //   meta: {
  //     permission: true,
  //     title: "Sản phẩm dành cho CTV",
  //     feature: "book-partner",
  //   },
  // },
  {
    path: "index/update",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/book"));
      });
    },
    name: "book-index-update",
    meta: {
      permission: true,
      title: "Chỉnh sửa dữ liệu sách",
      feature: "update-book"
    },
    hidden: true
  }, {
    path: "index/clothing-update",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/clothing"));
      });
    },
    name: "clothing-index-update",
    meta: {
      permission: true,
      title: "Chỉnh sửa dữ liệu đồng phục",
      feature: "update-clothing"
    },
    hidden: true
  }, {
    path: "category",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/category-book"));
      });
    },
    name: "category-book-index",
    meta: {
      permission: true,
      title: "Danh mục sách"
    }
  }, {
    path: "category-clothing",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/category-clothing"));
      });
    },
    name: "category-clothing-index",
    meta: {
      permission: true,
      title: "Danh mục đồng phục"
    }
  },
  // {
  //   path: "cover",
  //   component: () => import("@/views/cover-book"),
  //   name: "book-cover",
  //   meta: {
  //     permission: true,
  //     title: "Hình thức",
  //   },
  // },
  // {
  //   path: "publisher",
  //   component: () => import("@/views/publisher"),
  //   name: "book-publisher",
  //   meta: {
  //     permission: true,
  //     title: "Nhà xuất bản",
  //   },
  // },
  // {
  //   path: "author",
  //   component: () => import("@/views/author"),
  //   name: "book-author",
  //   meta: {
  //     permission: true,
  //     title: "Tác giả",
  //   },
  // },
  {
    path: "ship",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/address-ship"));
      });
    },
    name: "book-ship  ",
    meta: {
      permission: true,
      title: "Giá ship theo tỉnh, huyện"
    }
  }, {
    path: "city",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/address/City"));
      });
    },
    name: "address-city",
    meta: {
      permission: true,
      title: "Tỉnh thành"
    }
  }]
}, {
  path: "/preOrder",
  component: _layout.default,
  redirect: "/preOrder/strategy",
  permission: true,
  meta: {
    title: "Đặt sách",
    permission: true,
    icon: "dolly-flatbed-solid"
  },
  children: [{
    path: "strategy",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/strategy-order"));
      });
    },
    name: "order-strategy",
    meta: {
      permission: true,
      title: "Tất cả đợt đặt sách"
    }
  }, {
    path: "detail",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/strategy-order/detail"));
      });
    },
    name: "order-strategy-detail",
    meta: {
      permission: true,
      title: "Đơn hàng của các trường"
    }
  }, {
    path: "order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/pre-order"));
      });
    },
    name: "pre-order",
    meta: {
      permission: true,
      title: "Đơn đặt sách"
    }
  }, {
    path: "book-group",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order-book-group"));
      });
    },
    name: "order-strategy-book-group",
    meta: {
      permission: true,
      title: "Nhóm sách"
    }
  },
  // {
  //   path: "submit-order",
  //   component: () => import("@/views/strategy-order/detail"),
  //   name: "submit-order",
  //   meta: {
  //     permission: true,
  //     title: "Nộp bản đặt",
  //   },
  // },
  {
    path: "category-order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/category-order-book"));
      });
    },
    name: "category-order-book-index",
    meta: {
      permission: true,
      title: "Danh mục đặt sách"
    }
  }, {
    path: "division-commission",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/division/commission"));
      });
    },
    name: "division-commission",
    meta: {
      permission: true,
      title: "Hoa hồng phòng"
    }
  }, {
    path: "school-commission",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/school/commission"));
      });
    },
    name: "school-commission",
    meta: {
      permission: true,
      title: "Hoa hồng trường"
    }
  }]
}, {
  path: "/partner",
  component: _layout.default,
  redirect: "/partner/registration-form",
  permission: true,
  meta: {
    title: "Cộng tác viên",
    permission: true,
    icon: "receipt-solid"
  },
  children: [{
    path: "registration-form",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/partner-registration"));
      });
    },
    name: "partner-registration-form",
    meta: {
      permission: true,
      title: "Danh sách đăng ký"
    }
  }, {
    path: "list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/partner-list"));
      });
    },
    name: "partner-list",
    meta: {
      permission: true,
      title: "Danh sách CTV"
    }
  }, {
    path: "withdraw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/withdraw"));
      });
    },
    name: "partner-withdraw",
    meta: {
      permission: true,
      title: "Rút tiền"
    }
  }
  // {
  //   path: "config",
  //   component: () => import("@/views/config-partner"),
  //   name: "partner-config",
  //   meta: {
  //     permission: true,
  //     title: "Cấu hình",
  //   },
  // },
  ]
}, {
  path: "/customer",
  component: _layout.default,
  redirect: "/customer/index",
  permission: true,
  meta: {
    title: "Người dùng",
    permission: true,
    icon: "user"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer"));
      });
    },
    name: "customer-index",
    meta: {
      permission: true,
      title: "Danh sách người dùng"
    }
  }]
}, {
  path: "/order",
  component: _layout.default,
  redirect: "/order/index",
  permission: true,
  meta: {
    title: "Đơn hàng",
    permission: true,
    icon: "receipt-solid"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order"));
      });
    },
    name: "order-online",
    meta: {
      permission: true,
      title: "Tất cả đơn hàng"
    }
  }, {
    path: "promotion",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/promotion"));
      });
    },
    name: "order-promotion",
    meta: {
      permission: true,
      title: "Mã giảm giá(coupon)"
    }
  }
  // {
  //   path: "commission",
  //   component: () => import("@/views/order"),
  //   name: "order-commission",
  //   meta: {
  //     title: "Chiết khấu sp",
  //   },
  // },
  ]
}, {
  path: "/chat",
  component: _layout.default,
  redirect: "/chat/index",
  permission: true,
  meta: {
    title: "Chat",
    permission: true,
    icon: "comments-solid"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/chat"));
      });
    },
    name: "chat-index",
    meta: {
      permission: true,
      title: "Chat - Phụ huynh"
    }
  }, {
    path: "teacher",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/chat-teacher"));
      });
    },
    name: "chat-teacher",
    meta: {
      permission: true,
      title: "Chat - G.Viên"
    }
  }]
}, {
  path: "/school",
  component: _layout.default,
  redirect: "/school/division",
  permission: true,
  meta: {
    title: "Quản lý Sổ liên lạc",
    icon: "school-solid",
    permission: true
  },
  children: [{
    path: "division",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/division"));
      });
    },
    name: "division-index",
    meta: {
      permission: true,
      title: "Quản lý PGD"
    }
  }, {
    path: "school",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/school"));
      });
    },
    name: "school-index",
    meta: {
      permission: true,
      title: "Quản lý trường học"
    }
  }, {
    path: "grade",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/grade"));
      });
    },
    name: "grade-index",
    meta: {
      permission: true,
      title: "Quản lý Khối"
    }
  }, {
    path: "class-room",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/class-room"));
      });
    },
    name: "class-room-index",
    meta: {
      permission: true,
      title: "Quản lý Lớp học"
    }
  }, {
    path: "teacher",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/teacher"));
      });
    },
    name: "teacher-index",
    meta: {
      permission: true,
      title: "Quản lý Giáo viên"
    }
  }, {
    path: "student",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/student"));
      });
    },
    name: "student-index",
    meta: {
      permission: true,
      title: "Quản lý Học sinh"
    }
  }, {
    path: "config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config-contract"));
      });
    },
    name: "config-contract-index",
    meta: {
      permission: true,
      title: "Cấu hình sổ liên lạc"
    }
  }]
},
// {
//   path: "/preOrder",
//   component: Layout,
//   redirect: "/preOrder/strategy",
//   permission: true,
//   meta: {
//     title: "Đặt sách",
//     permission: true,
//     icon: "dolly-flatbed-solid",
//   },
//   children: [
//     {
//       path: "strategy",
//       component: () => import("@/views/strategy-order"),
//       name: "order-strategy",
//       meta: {
//         permission: true,
//         title: "Tất cả đợt đặt sách",
//       },
//     },
//     {
//       path: "detail",
//       component: () => import("@/views/strategy-order/detail"),
//       name: "order-strategy-detail",
//       meta: {
//         permission: true,
//         title: "Đơn hàng của các trường",
//       },
//     },
//     // {
//     //   path: "submit-order",
//     //   component: () => import("@/views/strategy-order/detail"),
//     //   name: "submit-order",
//     //   meta: {
//     //     permission: true,
//     //     title: "Nộp bản đặt",
//     //   },
//     // },
//     {
//       path: "category-order",
//       component: () => import("@/views/category-order-book"),
//       name: "category-order-book-index",
//       meta: {
//         permission: true,
//         title: "Danh mục đặt sách",
//       },
//     },
//     {
//       path: "division-commission",
//       component: () => import("@/views/division/commission"),
//       name: "division-commission",
//       meta: {
//         permission: true,
//         title: "Hoa hồng phòng",
//       },
//     },
//     {
//       path: "school-commission",
//       component: () => import("@/views/school/commission"),
//       name: "school-commission",
//       meta: {
//         permission: true,
//         title: "Hoa hồng trường",
//       },
//     },
//   ],
// },

{
  path: "/config-other",
  component: _layout.default,
  redirect: "/config-other/index",
  permission: true,
  meta: {
    title: "Quản lý page",
    icon: "cogs-solid"
  },
  children: [{
    path: "partner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/partner"));
      });
    },
    name: "config-other-partner",
    meta: {
      permission: true,
      title: "Quản lý đối tác"
    }
  }, {
    path: "reviews",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer-review"));
      });
    },
    name: "config-other-reviews",
    meta: {
      permission: true,
      title: "Quản lý đánh giá"
    }
  }, {
    path: "content",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/content"));
      });
    },
    name: "media-content",
    meta: {
      permission: true,
      title: "Nội dung"
    }
  }]
}, {
  path: "/config",
  component: _layout.default,
  redirect: "/config/index",
  permission: true,
  meta: {
    title: "Quản lý cấu hình",
    icon: "cogs-solid"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config"));
      });
    },
    name: "config-index",
    meta: {
      permission: true,
      title: "Quản lý cấu hình"
    }
  }]
}, {
  path: "/staff",
  component: _layout.default,
  redirect: "/staff/index",
  permission: true,
  meta: {
    title: "Nhân viên",
    icon: "user",
    permission: true
  },
  children: [{
    path: "user",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user"));
      });
    },
    name: "staff-index",
    meta: {
      permission: true,
      title: "Tất cả tài khoản"
    }
  }, {
    path: "role",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/permission/role"));
      });
    },
    name: "permission-role",
    meta: {
      permission: true,
      title: "Quản lý quyền"
    }
  }, {
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/profile/index"));
      });
    },
    name: "Profile",
    meta: {
      permission: true,
      title: "Trang cá nhân"
    }
  }]
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
var asyncRoutes = exports.asyncRoutes = [
  // 404 page must be placed at the end !!!
  // {
  //   path: '*',
  //   redirect: '/404',
  //   hidden: true
  // }
];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: "hash",
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;