import { render, staticRenderFns } from "./GradeModal.vue?vue&type=template&id=746f11ed"
import script from "./GradeModal.vue?vue&type=script&lang=js"
export * from "./GradeModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Admin\\Documents\\163hebec_web-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('746f11ed')) {
      api.createRecord('746f11ed', component.options)
    } else {
      api.reload('746f11ed', component.options)
    }
    module.hot.accept("./GradeModal.vue?vue&type=template&id=746f11ed", function () {
      api.rerender('746f11ed', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/grade/components/GradeModal.vue"
export default component.exports