"use strict";

var _interopRequireWildcard = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.BannerType = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var banner = _interopRequireWildcard(require("@/api/banner"));
var _ChooseFromMedia = _interopRequireDefault(require("@/components/Upload/ChooseFromMedia"));
var newsApi = _interopRequireWildcard(require("@/api/news"));
var promotionApi = _interopRequireWildcard(require("@/api/promotion"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var BannerType = exports.BannerType = {
  News: "NEWS",
  Promotion: "PROMOTION"
};
var _default = exports.default = {
  components: {
    ChooseFromMedia: _ChooseFromMedia.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])),
  data: function data() {
    return {
      BannerType: BannerType,
      bannerType: "",
      rules: {
        title: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập tiêu đề"
        },
        phone: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập số điện thoại"
        },
        banner: {
          required: true,
          trigger: "submit",
          message: "Vui lòng upload banner"
        },
        body: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập mô tả"
        }
      },
      submitLoading: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      dialogLoading: false,
      formData: {},
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      news: [],
      promotions: []
    };
  },
  created: function created() {
    this.fetchPromotions();
    this.fetchNews();
  },
  methods: {
    fetchPromotions: function fetchPromotions() {
      var _this = this;
      promotionApi.findAll().then(function (res) {
        _this.promotions = res.data.data;
      });
    },
    fetchNews: function fetchNews() {
      var _this2 = this;
      newsApi.findAll().then(function (res) {
        _this2.news = res.data.data;
      });
    },
    onChooseMedia: function onChooseMedia(path) {
      this.$set(this.formData, "thumbnail", path);
    },
    handleDelete: function handleDelete() {
      var _this3 = this;
      this.$confirm("Banner này sẽ bị ẩn, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        _this3.loadingButton = true;
        banner.remove(_this3.formData.id).then(function () {
          _this3.$message({
            type: "success",
            message: "Xoá thành công"
          });
          _this3.dialogVisible = false;
          _this3.$emit("create:success");
        }).finally(function () {
          return _this3.loadingButton = false;
        });
      });
    },
    createData: function createData() {
      var _this4 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this4.submitLoading = true;
          var tmp = _this4.bannerType == BannerType.News ? {
            newsId: _this4.formData.newsId
          } : {
            promotionId: _this4.formData.promotionId
          };
          var data = (0, _objectSpread2.default)({
            banner: _this4.formData
          }, tmp);
          banner.create(data).then(function () {
            _this4.$message({
              type: "success",
              message: "Thêm banner thành công"
            });
            _this4.$emit("create:success");
            _this4.dialogVisible = false;
          }).finally(function () {
            _this4.submitLoading = false;
          });
        }
      });
    },
    updateData: function updateData() {
      var _this5 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this5.submitLoading = true;
          var tmp = _this5.bannerType == BannerType.News ? {
            newsId: _this5.formData.newsId
          } : {
            promotionId: _this5.formData.promotionId
          };
          var data = (0, _objectSpread2.default)({
            banner: _this5.formData
          }, tmp);
          banner.update(_this5.formData.id, data).then(function () {
            _this5.$message({
              type: "success",
              message: "Cập nhật banner thành công"
            });
            _this5.$emit("update:success");
            _this5.dialogVisible = false;
          }).finally(function () {
            _this5.submitLoading = false;
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this6 = this;
      this.dialogVisible = true;
      this.dialogStatus = "update";
      this.bannerType = "";
      this.formData = (0, _objectSpread2.default)({}, row);
      if (row.promotion) {
        this.bannerType = BannerType.Promotion;
        this.formData.promotionId = row.promotion.id;
      }
      if (row.news) {
        this.bannerType = BannerType.News;
        this.formData.newsId = row.news.id;
      }
      this.$nextTick(function () {
        _this6.$refs["form"].clearValidate();
        _this6.$refs["editor"].setContent(_this6.formData.body);
      });
    },
    handleCreate: function handleCreate() {
      var _this7 = this;
      this.dialogVisible = true;
      this.dialogStatus = "create";
      this.bannerType = "";
      this.$nextTick(function () {
        _this7.$refs["form"].clearValidate();
        _this7.formData = {
          position: 0
        };
        _this7.$refs["editor"].setContent("");
      });
    },
    handleUploadSuccess: function handleUploadSuccess(res) {
      this.$set(this.formData, "thumbnail", res.data.path);
    }
  }
};