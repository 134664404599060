var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c("el-date-picker", {
            staticClass: "filter-item",
            attrs: {
              type: "daterange",
              "range-separator": "To",
              format: "dd/MM/yyyy",
              "value-format": "yyyy-MM-dd",
              "start-placeholder": "Ngày bắt đầu",
              "end-placeholder": "Ngày kết thúc",
            },
            on: { change: _vm.getDataChart },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-download" },
              on: { click: _vm.handleExport },
            },
            [_vm._v("Xuất excel")]
          ),
        ],
        1
      ),
      _c("bar-chart", {
        ref: "chart",
        attrs: {
          "data-chart": _vm.dataChart,
          series: _vm.series,
          xAxisValue: "day",
        },
        on: {
          "update:dataChart": function ($event) {
            _vm.dataChart = $event
          },
          "update:data-chart": function ($event) {
            _vm.dataChart = $event
          },
        },
      }),
      _c("p", { staticClass: "chart-title" }, [
        _vm._v(" Biểu đồ đơn hàng (Ngày) "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }