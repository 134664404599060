"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _SummaryGradeTable = _interopRequireDefault(require("./SummaryGradeTable.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    SummaryGradeTable: _SummaryGradeTable.default
  },
  props: ["summaryGrade"],
  data: function data() {
    return {
      grades: [],
      gradeId: undefined
    };
  },
  watch: {
    summaryGrade: {
      handler: function handler(val) {
        if (!val) {
          return;
        }
        this.grades = val.map(function (e, index) {
          return {
            id: e.id,
            name: e.name
          };
        });
      },
      deep: true,
      immediate: true
    }
  }
};