var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "8px", "padding-bottom": "24px" } },
    [
      _c("label", { staticStyle: { color: "black" }, attrs: { for: "" } }, [
        _vm._v("Thuộc tính sản phẩm "),
      ]),
      _c("br"),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        _vm._l(_vm.data, function (item, index) {
          return _c(
            "el-col",
            {
              key: index,
              staticStyle: { "margin-left": "0", "margin-right": "0" },
              attrs: { span: 12, offset: 0 },
            },
            [
              _c(
                "div",
                { staticClass: "panel" },
                [
                  _c("div", { staticClass: "head" }, [
                    _c("label", { staticClass: "label", attrs: { for: "" } }, [
                      _vm._v("Tên thuộc tính"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "detail" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Màu", clearable: "" },
                          model: {
                            value: item.name,
                            callback: function ($$v) {
                              _vm.$set(item, "name", $$v)
                            },
                            expression: "item.name",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", [
                    _c("div", { staticClass: "meta-detail" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label",
                          staticStyle: { "margin-bottom": "8px" },
                          attrs: { for: "" },
                        },
                        [_vm._v("Thuộc tính")]
                      ),
                    ]),
                  ]),
                  _vm._l(item.details, function (detail, i) {
                    return _c("div", { key: i, staticClass: "detail " }, [
                      _c(
                        "div",
                        { staticClass: "meta-detail" },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Xanh" },
                            model: {
                              value: detail.value,
                              callback: function ($$v) {
                                _vm.$set(detail, "value", $$v)
                              },
                              expression: "detail.value",
                            },
                          }),
                          false
                            ? _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: false,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                  },
                                ],
                                attrs: {
                                  disabled: index > 0,
                                  placeholder: "25,000",
                                  clearable: "",
                                },
                                model: {
                                  value: detail.finalPrice,
                                  callback: function ($$v) {
                                    _vm.$set(detail, "finalPrice", $$v)
                                  },
                                  expression: "detail.finalPrice",
                                },
                              })
                            : _vm._e(),
                          i > 0
                            ? _c("i", {
                                staticClass: "delete el-icon-delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDeleteDetail(
                                      i,
                                      item.details
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  }),
                  _c("i", {
                    staticClass: "add el-icon-plus",
                    on: {
                      click: function ($event) {
                        return _vm.addDetail(item)
                      },
                    },
                  }),
                ],
                2
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }