var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title:
          _vm.status == "create" ? "Thêm phòng GD&DT" : "Cập nhật phòng GD&DT",
        visible: _vm.visible,
        width: _vm.modalWidth,
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Thông tin", name: "info" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.form,
                    "label-position": "top",
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Tên", prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "" },
                                model: {
                                  value: _vm.form.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Địa chỉ" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "" },
                                model: {
                                  value: _vm.form.address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "address", $$v)
                                  },
                                  expression: "form.address",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.status == "update"
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "Tài khoản" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      disabled: "",
                                      value: _vm.form.username,
                                      placeholder: "",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Tỉnh thành" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { filterable: "", placeholder: "" },
                                  model: {
                                    value: _vm.form.addressCityId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "addressCityId", $$v)
                                    },
                                    expression: "form.addressCityId",
                                  },
                                },
                                _vm._l(_vm.cities, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Email" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "" },
                                model: {
                                  value: _vm.form.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "email", $$v)
                                  },
                                  expression: "form.email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Mật khẩu đầu tiên" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  value: _vm.form.isChangedDefaultPassword
                                    ? "Đã đổi mật khẩu"
                                    : _vm.form.firstPassword,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.status != "create"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Danh sách trường", name: "school-list" } },
                [
                  _c("SchoolTab", {
                    ref: "school-tab",
                    attrs: { "division-id": _vm.division.id },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm.tabName == "info"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      _vm.status == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              )
            : _vm._e(),
          _vm.tabName == "commission"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.updateCommission },
                },
                [_vm._v("Cập nhật hoa hồng")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }