"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.preOrderApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var preOrderApi = exports.preOrderApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/preOrder",
      params: params
    });
  },
  delivering: function delivering(id) {
    return (0, _request.default)({
      url: "/preOrder/".concat(id, "/deliveryStatus/delivering"),
      method: 'post'
    });
  },
  complete: function complete(id) {
    return (0, _request.default)({
      url: "/preOrder/".concat(id, "/deliveryStatus/complete"),
      method: 'post'
    });
  }
};