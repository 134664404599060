var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-plus" },
                on: { click: _vm.handleCreate },
              },
              [_vm._v("Thêm mới")]
            ),
          ],
          1
        ),
      ]),
      _c("SchoolTable", {
        attrs: { listLoading: _vm.listLoading, tableData: _vm.tableData },
        on: { handleBlock: _vm.handleBlock, handleUpdate: _vm.handleUpdate },
      }),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("SchoolModal", {
        ref: "dialog",
        attrs: { divisionId: _vm.divisionId },
        on: { "submit:success": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }