"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.classRoomApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var classRoomApi = exports.classRoomApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/classroom",
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/classroom",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/classroom/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/classroom/".concat(id, "/delete"),
      method: "post"
    });
  },
  transfer: function transfer(data) {
    return (0, _request.default)({
      url: "/classroom/transfer",
      data: data,
      method: "post"
    });
  },
  import: function _import(data) {
    return (0, _request.default)({
      url: "/classroom/import",
      data: data,
      method: "post"
    });
  }
};