"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
var _this = void 0; //
//
//
//
//
//
//
require("echarts/theme/macarons"); // echarts theme

var animationDuration = 6000;
var _default2 = exports.default = {
  mixins: [_resize.default],
  props: {
    yAxisFormater: {
      type: String,
      default: "{value} đ"
    },
    dataChart: Array,
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    xAxisValue: {
      type: String,
      default: "date"
    },
    series: {
      type: Array,
      default: function _default() {
        return [{
          name: "Doanh số",
          type: "bar",
          itemStyle: {
            color: "#1b75bc"
          },
          yAxisIndex: 0,
          data: _this.dataChart.map(function (item) {
            return item.total["moneyIncome"] + item.total["moneyCommission"];
          })
        }];
      }
    },
    height: {
      type: String,
      default: "300px"
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      var _this2 = this;
      var self = this;
      this.chart = _echarts.default.init(this.$el, "macarons");
      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: 10,
          left: "2%",
          right: "2%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [{
          type: "category",
          data: this.dataChart.map(function (item) {
            return item[_this2.xAxisValue];
          }),
          axisTick: {
            alignWithLabel: true
          }
        }],
        yAxis: [{
          type: "value",
          name: "y0",
          axisLabel: {
            formatter: this.yAxisFormater
          }
        }, {
          type: "value",
          name: "y1",
          interval: 5,
          axisLabel: {
            formatter: "{value} đơn"
          }
        }],
        series: this.series
      });
    }
  }
};