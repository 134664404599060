var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: { "prefix-icon": "el-icon-search", placeholder: "Tìm tên" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.fetchData($event)
              },
            },
            model: {
              value: _vm.listQuery.search,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "search", $$v)
              },
              expression: "listQuery.search",
            },
          }),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: "Tìm theo phòng GD",
                    clearable: "",
                  },
                  on: { change: _vm.handleChangeDivision },
                  model: {
                    value: _vm.listQuery.divisionId,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "divisionId", $$v)
                    },
                    expression: "listQuery.divisionId",
                  },
                },
                _vm._l(_vm.divisions, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: "Trường học",
                    clearable: "",
                  },
                  on: { change: _vm.fetchData },
                  model: {
                    value: _vm.listQuery.schoolId,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "schoolId", $$v)
                    },
                    expression: "listQuery.schoolId",
                  },
                },
                _vm._l(_vm.schools, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: "" + item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.fetchData },
            },
            [_vm._v(" Tìm ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.dataTable, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "Tên trường",
              prop: "school.name",
              "class-name": "text-bold",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "Tên phòng", prop: "school.division.name" },
          }),
          _c("el-table-column", {
            attrs: { align: "right", label: "Số lượng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("toThousandFilter")(row.totalQuantity)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "right", label: "Tổng tiền" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("toThousandFilter")(row.totalPrice)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Chi tiết",
                          placement: "top",
                          effect: "dark",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-info",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleDetail(row)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("SummaryModal", { ref: "summary-modal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }