"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.submitPreOrderApi = exports.strategyOrderApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var strategyOrderApi = exports.strategyOrderApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/strategyOrder",
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id)
    });
  },
  reportDivision: function reportDivision(id, params) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/report/divisions"),
      params: params
    });
  },
  divisionDetail: function divisionDetail(strategyId, divisionId) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(strategyId, "/report/divisions/").concat(divisionId)
    });
  },
  reportSchool: function reportSchool(id, params) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/report/schools"),
      params: params
    });
  },
  reportSchoolDetail: function reportSchoolDetail(strategyId, schoolId) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(strategyId, "/report/schools/").concat(schoolId)
    });
  },
  reportPreOrder: function reportPreOrder(id, params) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/report/schools/preOrder"),
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/strategyOrder",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/delete"),
      method: "post"
    });
  },
  report: function report(id) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/report")
    });
  },
  open: function open(id) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/open"),
      method: "post"
    });
  },
  close: function close(id) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/close"),
      method: "post"
    });
  },
  divisionsOpen: function divisionsOpen(id, divisionId) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/divisions/").concat(divisionId, "/open"),
      method: "post"
    });
  },
  divisionsClose: function divisionsClose(id, divisionId) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/divisions/").concat(divisionId, "/close"),
      method: "post"
    });
  },
  schoolsOpen: function schoolsOpen(id, schoolId) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/schools/").concat(schoolId, "/open"),
      method: "post"
    });
  },
  schoolsClose: function schoolsClose(id, schoolId) {
    return (0, _request.default)({
      url: "/strategyOrder/".concat(id, "/schools/").concat(schoolId, "/close"),
      method: "post"
    });
  }
};
var submitPreOrderApi = exports.submitPreOrderApi = {
  cancel: function cancel(id) {
    return (0, _request.default)({
      url: "/submitPreOrder/".concat(id, "/delete"),
      method: "post"
    });
  }
};