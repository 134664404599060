var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { transform: "translateY(10px)" },
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleUpate },
            },
            [_vm._v(" Cập nhật ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.categories, border: "", fit: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "Danh mục sách", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Tổng của trường (%)" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("input-money", {
                      attrs: { options: { maximumValue: 100 } },
                      model: {
                        value: row.commisionValue,
                        callback: function ($$v) {
                          _vm.$set(row, "commisionValue", $$v)
                        },
                        expression: "row.commisionValue",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }