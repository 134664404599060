var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.dialogStatus == "update" ? "Cập nhật banner" : "Thêm banner",
        visible: _vm.dialogVisible,
        width: "600px",
        top: "15px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "80px",
                "label-position": "top",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Banner dành cho" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: _vm.BannerType.News },
                      on: { change: function ($event) {} },
                      model: {
                        value: _vm.bannerType,
                        callback: function ($$v) {
                          _vm.bannerType = $$v
                        },
                        expression: "bannerType",
                      },
                    },
                    [_vm._v(" Tin tức ")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: _vm.BannerType.Promotion },
                      on: { change: function ($event) {} },
                      model: {
                        value: _vm.bannerType,
                        callback: function ($$v) {
                          _vm.bannerType = $$v
                        },
                        expression: "bannerType",
                      },
                    },
                    [_vm._v(" Khuyến mãi ")]
                  ),
                ],
                1
              ),
              _vm.bannerType == _vm.BannerType.News
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Tin tức" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.formData.newsId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "newsId", $$v)
                            },
                            expression: "formData.newsId",
                          },
                        },
                        _vm._l(_vm.news, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.title, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.bannerType == _vm.BannerType.Promotion
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Khuyến mãi" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.formData.promotionId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "promotionId", $$v)
                            },
                            expression: "formData.promotionId",
                          },
                        },
                        _vm._l(_vm.promotions, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.title, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "Vị trí hiển thị" } },
                [
                  _c("input-money", {
                    model: {
                      value: _vm.formData.position,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "position", $$v)
                      },
                      expression: "formData.position",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Banner (Tỷ lệ 7/3)", prop: "thumbnail" },
                    },
                    [
                      _c("ChooseFromMedia", {
                        attrs: {
                          height: "170px",
                          width: "400px",
                          "path-image": _vm.formData.thumbnail,
                        },
                        on: { onChooseMedia: _vm.onChooseMedia },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm.dialogStatus != "create"
            ? _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.handleDelete } },
                [_vm._v("Xoá")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.submitLoading, type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogStatus == "create"
                    ? _vm.createData()
                    : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }