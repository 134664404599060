var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "Chi tiết đơn hàng",
        visible: _vm.visible,
        width: "800px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "8px" } },
        [
          _c("label", { attrs: { for: "" } }, [_vm._v("Người dùng: ")]),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: _vm.data.expoToken,
                placement: "top",
              },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.data.customer && _vm.data.customer.name)),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticStyle: { "margin-bottom": "8px" } }, [
        _c("label", { attrs: { for: "" } }, [_vm._v("Học sinh: ")]),
        _c("span", [_vm._v(_vm._s(_vm.data.studentName))]),
      ]),
      _c(
        "el-table",
        { attrs: { data: _vm.data.preOrderDetails, border: "", stripe: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "Tên SP", prop: "book.name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Thuộc tính" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.bookAttribute1
                      ? _c("div", [
                          _vm._v(
                            " - " +
                              _vm._s(row.book.attribute1) +
                              ": " +
                              _vm._s(row.bookAttribute1.value) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    row.bookAttribute2
                      ? _c("div", [
                          _vm._v(
                            " - " +
                              _vm._s(row.book.attribute2) +
                              ": " +
                              _vm._s(row.bookAttribute2.value) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Số lượng", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("toThousandFilter")(row.quantity)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Giá bán" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("toThousandFilter")(row.finalPrice)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thành tiền" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("toThousandFilter")(
                            row.finalPrice * row.quantity
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "flex-end" } },
        [
          _c("ul", { staticClass: "detail-order" }, [
            _c("li", [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Tổng số lượng sách:"),
              ]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm._f("toThousandFilter")(_vm.getTotalQuantity))
                ),
              ]),
            ]),
            _c("li", [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tổng tiền:")]),
              _c("span", [
                _vm._v(_vm._s(_vm._f("toThousandFilter")(_vm.data.moneyFinal))),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }