"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TransactionTypeTrans = exports.TransactionType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var TransactionType;
(function (TransactionType) {
  TransactionType["ProductCommission"] = "PRODUCT_COMMISSION";
  TransactionType["Withdraw"] = "WITHDRAW";
  TransactionType["RejectWithdraw"] = "REJECT_WITHDRAW";
})(TransactionType || (exports.TransactionType = TransactionType = {}));
var TransactionTypeTrans = exports.TransactionTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, TransactionType.ProductCommission, 'Hoa hồng từ đơn hàng'), TransactionType.Withdraw, 'Rút tiền'), TransactionType.RejectWithdraw, 'Hủy lệnh rút tiền');