"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.chatAdminApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var chatAdminApi = exports.chatAdminApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/chatAdmin/customers",
      params: params
    });
  },
  findOne: function findOne(id, params) {
    return (0, _request.default)({
      url: "/chatAdmin/customers/".concat(id),
      params: params
    });
  },
  chat: function chat(id, data) {
    return (0, _request.default)({
      url: "/chatAdmin/customers/".concat(id),
      method: "post",
      data: data
    });
  },
  deleteConversation: function deleteConversation(id) {
    return (0, _request.default)({
      url: "/chatAdmin/customers/".concat(id, "/delete"),
      method: "post"
    });
  }
};