import { render, staticRenderFns } from "./SchoolTable.vue?vue&type=template&id=30351272"
import script from "./SchoolTable.vue?vue&type=script&lang=js"
export * from "./SchoolTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Admin\\Documents\\163hebec_web-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('30351272')) {
      api.createRecord('30351272', component.options)
    } else {
      api.reload('30351272', component.options)
    }
    module.hot.accept("./SchoolTable.vue?vue&type=template&id=30351272", function () {
      api.rerender('30351272', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/school/components/SchoolTable.vue"
export default component.exports