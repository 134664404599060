"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.commissionCompanyApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var commissionCompanyApi = exports.commissionCompanyApi = {
  createDivision: function createDivision(data) {
    return (0, _request.default)({
      url: "/commissionCompany/division",
      method: "post",
      data: data
    });
  },
  createSchool: function createSchool(data) {
    return (0, _request.default)({
      url: "/commissionCompany/school",
      method: "post",
      data: data
    });
  },
  findAllSchool: function findAllSchool(params) {
    return (0, _request.default)({
      url: "/commissionCompany/school",
      params: params
    });
  }
};