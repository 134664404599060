var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "Danh sách media",
        visible: _vm.visible,
        width: "1200px",
        top: "30px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("MediaControl", {
        attrs: {
          selected: _vm.selected,
          "media-type": _vm.MediaType.Choose,
          "on-choose": _vm.onChooseMedia,
          multiple: _vm.multiple,
          "handle-choose-media": _vm.handleChooseMedia,
        },
      }),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm.selected
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleChooseMedia },
                },
                [_vm._v("Chọn media")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }