var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-container", attrs: { id: "news" } },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          !_vm.removable
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-edit" },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v(" Thêm tin tức ")]
              )
            : _vm._e(),
          _vm.removable && _vm.selection.length
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: {
                    type: "primary",
                    icon: "el-icon-refresh",
                    loading: _vm.loadingDelete,
                  },
                  on: { click: _vm.handleRestores },
                },
                [_vm._v(" Khôi phục ")]
              )
            : _vm._e(),
          _vm.selection.length
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: {
                    type: "danger",
                    icon: "el-icon-delete",
                    loading: _vm.loadingDelete,
                  },
                  on: {
                    click: function ($event) {
                      _vm.removable
                        ? _vm.handleDeleteComplete()
                        : _vm.handleDelete()
                    },
                  },
                },
                [_vm._v(" Xóa ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "technician-table",
          attrs: { data: _vm.tableData, border: "", fit: "" },
          on: { "selection-change": _vm.handleSelection },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "50" } }),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "News",
              width: "320",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "info-box" }, [
                      _c("div", { staticClass: "info" }, [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(row.name)),
                        ]),
                      ]),
                      _c("img", {
                        staticClass: "image",
                        attrs: {
                          src: _vm.baseUrlMedia + row.thumbnail,
                          alt: "",
                        },
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "Tiêu đề", prop: "title" } }),
          _c("el-table-column", {
            attrs: { prop: "body", label: "Mô tả" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          display: "-webkit-box",
                          "-webkit-box-orient": "vertical",
                          "-webkit-line-clamp": "5",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.stripHtml(row.body)) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          !_vm.removable
            ? _c("el-table-column", {
                attrs: { align: "center", label: "Nổi bật" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          !row.isHighlight && !_vm.highlightNews
                            ? _c("el-button", {
                                attrs: {
                                  icon: "el-icon-star-off",
                                  type: "primary",
                                  size: "mini",
                                  loading: row.loadingStar,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleChangeHightlight(row)
                                  },
                                },
                              })
                            : _vm._e(),
                          row.isHighlight
                            ? _c("el-button", {
                                staticStyle: { color: "yellow" },
                                attrs: {
                                  icon: "el-icon-star-on",
                                  type: "warning",
                                  size: "mini",
                                  loading: row.loadingStar,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleChangeHightlight(row)
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1849487961
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [_vm._v(_vm._s(_vm.$formatDate(row.createdAt)))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Hành động",
              width: "250",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Chi tiết",
                          placement: "top",
                          effect: "dark",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            icon: "el-icon-info",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleEdit(row)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.removable
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Khôi phục",
                              placement: "top",
                              effect: "dark",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-refresh",
                                size: "mini",
                                plain: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleRestore(row)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    !row.isBlock
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Xoá",
                              placement: "top",
                              effect: "dark",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "danger",
                                icon: "el-icon-delete",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.removable
                                    ? _vm.handleRemove(row)
                                    : _vm.handleBlock(row)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getData,
        },
      }),
      _c("news-dialog", {
        ref: "dialog",
        on: { "create:success": _vm.getData, "update:success": _vm.getData },
      }),
      _c("StoreDialog", {
        ref: "store-dialog",
        attrs: { "dialog-disabled": true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }