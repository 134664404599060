"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MediaControl = _interopRequireDefault(require("./MediaControl"));
var _Media = require("./components/Media.vue");
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    MediaControl: _MediaControl.default
  },
  data: function data() {
    return {
      MediaType: _Media.MediaType
    };
  }
};