"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _Book = _interopRequireDefault(require("./Book"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Book: _Book.default
  },
  data: function data() {
    return {
      update: 0,
      activeTab: "CLOTHING"
    };
  },
  methods: {
    updateTable: function updateTable() {
      this.update += 1;
    },
    handleTabClick: function handleTabClick(tab) {
      this.activeTab = tab.name; // Cập nhật tab hiện tại
    }
  }
};