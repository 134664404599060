"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var authApi = exports.authApi = {
  update: function update(data) {
    return (0, _request.default)({
      url: "/auth/profile",
      method: "post",
      data: data
    });
  }
};