"use strict";

var _interopRequireWildcard = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var news = _interopRequireWildcard(require("@/api/news"));
var _SingleImage = _interopRequireDefault(require("@/components/Upload/SingleImage"));
var _ChooseFromMedia = _interopRequireDefault(require("@/components/Upload/ChooseFromMedia"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default,
    SingleImage: _SingleImage.default,
    ChooseFromMedia: _ChooseFromMedia.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])),
  data: function data() {
    return {
      isMountedEditor: false,
      rules: {
        title: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập tiêu đề"
        },
        storeId: {
          required: true,
          trigger: "submit",
          message: "Vui lòng chọn cửa hàng"
        },
        thumbnail: {
          required: true,
          trigger: "submit",
          message: "Vui lòng upload banner"
        },
        body: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập mô tả"
        }
      },
      submitLoading: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      dialogLoading: false,
      formData: {},
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      stores: []
    };
  },
  created: function created() {},
  methods: {
    onChooseMedia: function onChooseMedia(thumbnail) {
      this.$set(this.formData, "thumbnail", thumbnail);
    },
    onChooseMediaImage: function onChooseMediaImage(image) {
      this.$set(this.formData, "image", image);
    },
    createData: function createData() {
      var _this = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this.submitLoading = true;
          var data = {
            news: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this.formData), {}, {
              body: (0, _utils.convertLinkYoutube)(_this.formData.body)
            })
          };
          news.create(data).then(function () {
            _this.$message({
              type: "success",
              message: "Thêm thành công"
            });
            _this.$emit("create:success");
            _this.dialogVisible = false;
          }).finally(function () {
            _this.submitLoading = false;
          });
        }
      });
    },
    updateData: function updateData() {
      var _this2 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this2.submitLoading = true;
          var data = {
            news: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this2.formData), {}, {
              body: (0, _utils.convertLinkYoutube)(_this2.formData.body)
            })
          };
          news.update(_this2.formData.id, data).then(function () {
            _this2.$message({
              type: "success",
              message: "Cập nhật tin tức thành công"
            });
            _this2.$emit("update:success");
            _this2.dialogVisible = false;
          }).finally(function () {
            _this2.submitLoading = false;
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this3 = this;
      this.dialogVisible = true;
      this.dialogStatus = "update";
      this.$nextTick(function () {
        _this3.$refs["form"].clearValidate();
        _this3.formData = (0, _objectSpread2.default)({}, row);
        if (_this3.isMountedEditor) {
          _this3.$refs["editor"].setContent(_this3.formData.body);
        }
      });
    },
    handleCreate: function handleCreate(type) {
      var _this4 = this;
      this.dialogVisible = true;
      this.dialogStatus = "create";
      this.$nextTick(function () {
        _this4.$refs["form"].clearValidate();
        _this4.formData = {};
        _this4.$set(_this4.formData, "type", type);
        if (_this4.isMountedEditor) {
          _this4.$refs["editor"].setContent("");
        }
      });
    },
    beforeUpload: function beforeUpload(file) {
      this.dialogLoading = true;
      var isJPG = file.type.includes("image");
      if (!isJPG) {
        this.dialogLoading = false;
        this.$message.error("Ảnh không đúng định dạng!");
      }
      return isJPG;
    },
    handleUploadSuccess: function handleUploadSuccess(res, file) {
      this.$set(this.formData, "thumbnail", res.data.path);
      this.dialogLoading = false;
    }
  }
};