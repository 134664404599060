"use strict";

var _interopRequireWildcard = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var notification = _interopRequireWildcard(require("@/api/notification"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])),
  data: function data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      dialogStatus: "create",
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      formData: {},
      rules: {
        title: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập tiêu đề"
        },
        body: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập nội dung"
        }
      }
    };
  },
  methods: {
    beforeUpload: function beforeUpload(file) {
      this.dialogLoading = true;
      var isJPG = file.type.includes("image");
      if (!isJPG) {
        this.dialogLoading = false;
        this.$message.error("Ảnh không đúng định dạng!");
      }
      return isJPG;
    },
    handleCreate: function handleCreate() {
      var _this = this;
      this.dialogVisible = true;
      this.dialogStatus = "create";
      this.formData = {};
      this.$nextTick(function () {
        _this.$refs["form"].clearValidate();
      });
    },
    handleUploadSuccess: function handleUploadSuccess(res, file) {
      this.$set(this.formData, "thumbnail", res.data.path);
      this.dialogLoading = false;
    },
    handleUpdate: function handleUpdate(data) {
      var _this2 = this;
      this.dialogVisible = true;
      this.dialogStatus = "update";
      this.$nextTick(function () {
        _this2.$refs["form"].clearValidate();
        _this2.formData = (0, _objectSpread2.default)({}, data);
      });
    },
    createData: function createData() {
      var _this3 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this3.submitLoading = true;
          var data = {
            notification: _this3.formData,
            newsId: _this3.formData.newsId
          };
          notification.create(data).then(function (res) {
            if (res.status) {
              _this3.$message({
                type: "success",
                message: "Thêm mới thành công"
              });
              _this3.$emit("create:success");
              _this3.dialogVisible = false;
            }
          }).finally(function () {
            _this3.submitLoading = false;
          });
        }
      });
    },
    updateData: function updateData() {
      var _this4 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          var data = {
            notification: _this4.formData
          };
          _this4.submitLoading = true;
          notification.update(_this4.formData.id, data).then(function (res) {
            if (res.status) {
              _this4.$message({
                type: "success",
                message: "Cập nhật thành công"
              });
              _this4.$emit("update:success");
              _this4.dialogVisible = false;
            }
          }).finally(function () {
            _this4.submitLoading = false;
          });
        }
      });
    }
  }
};