"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _division = require("@/api/division");
var _commissionCompany = require("@/api/commission-company.js");
var _vuex = require("vuex");
var _preorderCategoryBook = require("@/api/preorder-category-book");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      categories: [],
      divisionId: null,
      loading: false
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    divisions: function divisions(state) {
      return state.division.list;
    }
  })),
  watch: {
    categories: {
      handler: function handler(val) {
        val.forEach(function (element) {
          element.commisionRemain = element.commisionValue - element.commisionSchool;
        });
      },
      deep: true
    }
  },
  created: function created() {
    this.fetchCategories();
    this.$store.dispatch("division/fetchList");
  },
  methods: {
    validComission: function validComission() {
      for (var i = 0; i < this.categories.length; i++) {
        if (this.categories[i].commisionSchool > this.categories[i].commisionValue) {
          return false;
        }
      }
      return true;
    },
    handleUpate: function handleUpate() {
      var _this = this;
      var details = [];
      this.categories.forEach(function (e) {
        details.push({
          remain: e.commisionRemain,
          school: e.commisionSchool,
          value: e.commisionValue,
          preOrderCategoryId: e.id
        });
      });
      this.loading = true;
      _commissionCompany.commissionCompanyApi.createDivision({
        details: details,
        divisionId: this.divisionId
      }).then(function () {
        _this.$notify.success({
          title: "Cập nhật thành công"
        });
      }).finally(function () {
        return _this.loading = false;
      });
    },
    fetchCommission: function fetchCommission() {
      var _this2 = this;
      _division.divisionApi.commission(this.divisionId).then(function (res) {
        var commissionCompanies = res.data.commissionCompanies;
        _this2.categories.forEach(function (element) {
          var commission = commissionCompanies.find(function (e) {
            return e.preOrderCategory.id == element.id;
          });
          if (commission) {
            _this2.$set(element, "commisionValue", commission.value);
            _this2.$set(element, "commisionSchool", commission.school);
            _this2.$set(element, "commisionRemain", commission.remain);
          } else {
            _this2.$set(element, "commisionValue", 0);
            _this2.$set(element, "commisionSchool", 0);
            _this2.$set(element, "commisionRemain", 0);
          }
        });
      });
    },
    fetchCategories: function fetchCategories() {
      var _this3 = this;
      _preorderCategoryBook.preOrderCategoryBookApi.findAll({
        root: true
      }).then(function (res) {
        _this3.categories = res.data.preOrderCategories;
      });
    },
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (row.commisionSchool > row.commisionValue) {
        return "warning-row";
      }
      return "";
    }
  }
};