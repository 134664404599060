"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vuex = require("vuex");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "EditorSlideUpload",
  components: {
    ListMediaModal: function ListMediaModal() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/media/components/ListMediaModal"));
      });
    }
  },
  props: {
    urlUpload: {
      type: String,
      default: "/news/upload"
    },
    color: {
      type: String,
      default: "#1890ff"
    }
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])),
  data: function data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      dialogVisible: false,
      listObj: {},
      fileList: []
    };
  },
  methods: {
    onChooseMedia: function onChooseMedia(res) {
      var _this = this;
      res.forEach(function (element, index) {
        var current = (0, _moment.default)().valueOf() + index;
        var url = _this.baseUrlMedia + element.thumbnail;
        _this.listObj[current] = {
          url: url,
          hasSuccess: true
        };
        _this.fileList.push({
          name: element.name,
          url: url
        });
      });
    },
    handleChooseMedia: function handleChooseMedia() {
      this.$refs["media-modal"].handleOpen();
    },
    checkAllSuccess: function checkAllSuccess() {
      var _this2 = this;
      return Object.keys(this.listObj).every(function (item) {
        return _this2.listObj[item].hasSuccess;
      });
    },
    handleSubmit: function handleSubmit() {
      var _this3 = this;
      var arr = Object.keys(this.listObj).map(function (v) {
        return _this3.listObj[v];
      });
      if (!this.checkAllSuccess()) {
        this.$message("Please wait for all images to be uploaded successfully. If there is a network problem, please refresh the page and upload again!");
        return;
      }
      this.$emit("successCBK", arr);
      this.listObj = {};
      this.fileList = [];
      this.dialogVisible = false;
    },
    handleSuccess: function handleSuccess(response, file) {
      var uid = file.uid;
      var objKeyArr = Object.keys(this.listObj);
      for (var i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          this.listObj[objKeyArr[i]].url = this.baseUrlMedia + response.data.path;
          this.listObj[objKeyArr[i]].hasSuccess = true;
          return;
        }
      }
    },
    handleRemove: function handleRemove(file) {
      var uid = file.uid;
      var objKeyArr = Object.keys(this.listObj);
      for (var i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]];
          return;
        }
      }
    },
    beforeUpload: function beforeUpload(file) {
      var _self = this;
      var _URL = window.URL || window.webkitURL;
      var fileName = file.uid;
      this.listObj[fileName] = {};
      return new Promise(function (resolve, reject) {
        var img = new Image();
        img.src = _URL.createObjectURL(file);
        img.onload = function () {
          _self.listObj[fileName] = {
            hasSuccess: false,
            uid: file.uid,
            width: this.width,
            height: this.height
          };
        };
        resolve(true);
      });
    }
  }
};