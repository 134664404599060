import { render, staticRenderFns } from "./HistorySubmitDivision.vue?vue&type=template&id=c897ee86"
import script from "./HistorySubmitDivision.vue?vue&type=script&lang=js"
export * from "./HistorySubmitDivision.vue?vue&type=script&lang=js"
import style0 from "./HistorySubmitDivision.vue?vue&type=style&index=0&id=c897ee86&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Admin\\Documents\\163hebec_web-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c897ee86')) {
      api.createRecord('c897ee86', component.options)
    } else {
      api.reload('c897ee86', component.options)
    }
    module.hot.accept("./HistorySubmitDivision.vue?vue&type=template&id=c897ee86", function () {
      api.rerender('c897ee86', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/strategy-order/detail/components/HistorySubmitDivision.vue"
export default component.exports