var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-panel" }, [
    _c("div", { staticClass: "left-wrapper" }, [
      _c("div", { staticClass: "card-panel-text" }, [_vm._v(" Sách ")]),
      _c(
        "div",
        { staticClass: "card-panel-icon-wrapper icon-money" },
        [_c("font-awesome-icon", { attrs: { icon: "book", size: "3x" } })],
        1
      ),
    ]),
    _c("div", { staticClass: "card-panel-description" }, [
      _c(
        "div",
        [
          _c("span", [_vm._v(" Sách:")]),
          _c("count-to", {
            staticClass: "card-panel-num",
            attrs: { "start-val": 0, "end-val": _vm.data.book, duration: 3200 },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("span", [_vm._v("Danh mục:")]),
          _c("count-to", {
            staticClass: "card-panel-num",
            attrs: {
              "start-val": 0,
              "end-val": _vm.data.categoryParent,
              duration: 3200,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("span", [_vm._v("Danh mục con:")]),
          _c("count-to", {
            staticClass: "card-panel-num",
            attrs: {
              "start-val": 0,
              "end-val": _vm.data.categoryChildren,
              duration: 3200,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("span", [_vm._v("NXB:")]),
          _c("count-to", {
            staticClass: "card-panel-num",
            attrs: {
              "start-val": 0,
              "end-val": _vm.data.publisher,
              duration: 3200,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("span", [_vm._v("Hình thức:")]),
          _c("count-to", {
            staticClass: "card-panel-num",
            attrs: {
              "start-val": 0,
              "end-val": _vm.data.cover,
              duration: 3200,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }