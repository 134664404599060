"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _PanThumb = _interopRequireDefault(require("@/components/PanThumb"));
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/api/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    PanThumb: _PanThumb.default
  },
  data: function data() {
    return {
      user: {},
      loading: false,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA
    };
  },
  created: function created() {
    this.user = _store.default.getters.info;
  },
  methods: {
    handleCancel: function handleCancel() {
      this.$set(this.user, "isEdit", false);
    },
    updateUser: function updateUser() {
      var _this = this;
      this.loading = true;
      _auth.authApi.update({
        staff: this.user.tmp
      }).then(function () {
        _this.$notify.success({
          title: "Thông báo",
          message: "Cập nhật profile thành công!"
        });
        _this.user = (0, _objectSpread2.default)({}, _this.user.tmp);
        _this.$set(_this.user, "isEdit", false);
      }).finally(function () {
        _this.loading = false;
      });
    },
    handleUpdate: function handleUpdate() {
      this.$set(this.user, "isEdit", true);
      this.$set(this.user, "tmp", (0, _objectSpread2.default)({}, this.user));
    }
  }
};