var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", attrs: { id: "notification" } },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: {
                click: function ($event) {
                  return _vm.handleAdd()
                },
              },
            },
            [_vm._v("Thêm")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.tableData, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", { attrs: { prop: "title", label: "Tiêu đề" } }),
          _c("el-table-column", { attrs: { prop: "body", label: "Nội dung" } }),
          _c("el-table-column", {
            attrs: { label: "Thông báo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-checkbox", { attrs: { value: row.isNoticed } }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo", prop: "createdAt" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(_vm.formatDate(row.createdAt)))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    !row.isNoticed
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Thông báo",
                              placement: "top",
                              effect: "dark",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-message-solid",
                                size: "mini",
                                plain: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleNotice(row)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    !row.isNoticed
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Cập nhật",
                              placement: "top",
                              effect: "dark",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                size: "mini",
                                icon: "el-icon-info",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit(row)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Xoá",
                          placement: "top",
                          effect: "dark",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "danger",
                            icon: "el-icon-delete",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(row)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _c("Notification", {
        ref: "dialog",
        on: {
          "update:success": _vm.getTableData,
          "create:success": _vm.getTableData,
        },
      }),
      _c("NewsDialog", { ref: "news-dialog", attrs: { disableForm: true } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }