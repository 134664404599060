"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _BarChart = _interopRequireDefault(require("../BarChart"));
var _dashboard = require("@/api/dashboard");
var _moment = _interopRequireDefault(require("moment"));
var _variables = _interopRequireDefault(require("@/styles/variables.scss"));
var _Export2Excel = require("@/vendor/Export2Excel");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    BarChart: _BarChart.default
  },
  data: function data() {
    return {
      series: [],
      date: [(0, _moment.default)().subtract(2, "month").format("YYYY-MM"), (0, _moment.default)().format("YYYY-MM")],
      dataChart: []
    };
  },
  created: function created() {
    this.getDataChart();
  },
  methods: {
    handleExport: function handleExport() {
      var header = ["Tháng", "Doanh số", "Đơn hàng"];
      var data = [];
      var total = {
        money: 0,
        order: 0
      };
      this.dataChart.forEach(function (e) {
        data.push([e.month, e.moneyFinal, e.total]);
        total.money += e.moneyFinal;
        total.order += e.total;
      });
      var startMonth = (0, _moment.default)(this.date[0], "YYYY-MM").format("MM/YYYY");
      var endMonth = (0, _moment.default)(this.date[0], "YYYY-MM").format("MM/YYYY");
      data.push(["Tổng", total.money, total.order]);
      (0, _Export2Excel.export_json_to_excel)({
        data: data,
        header: header,
        filename: "\u0110\u01A1n h\xE0ng t\u1EEB th\xE1ng ".concat(startMonth, " - ").concat(endMonth)
      });
    },
    convertSeries: function convertSeries() {
      var _this = this;
      var columnSeri = {
        name: "Doanh số",
        type: "bar",
        itemStyle: {
          color: _variables.default.primaryColor
        },
        yAxisIndex: 0,
        data: this.dataChart.map(function (item) {
          return item.moneyFinal;
        })
      };
      var lineSeri = {
        name: "Đơn hàng",
        type: "line",
        yAxisIndex: 1,
        data: this.dataChart.map(function (item) {
          return item.total;
        })
      };
      this.series = [columnSeri, lineSeri];
      this.$nextTick(function () {
        _this.$refs.chart.initChart();
      });
    },
    getDataChart: function getDataChart() {
      var _this2 = this;
      var listQuery = {
        from: (0, _moment.default)(this.date[0], "YYYY-MM").unix(),
        to: (0, _moment.default)(this.date[1], "YYYY-MM").unix()
      };
      _dashboard.dashboardApi.orderMonthChart(listQuery).then(function (res) {
        _this2.dataChart = res.data;
        _this2.convertSeries();
      });
    }
  }
};