var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingModal,
          expression: "loadingModal",
        },
      ],
      staticClass: "category-book-modal",
      attrs: {
        "close-on-click-modal": false,
        title: _vm.status == "create" ? "Thêm danh mục" : "Cập nhật danh mục",
        visible: _vm.visible,
        width: "500px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-position": "top",
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Loại danh mục", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(this.categoryTypes, function (type) {
                          return _c("el-option", {
                            key: type.value,
                            attrs: { label: type.label, value: type.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.levelSelected
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Ưu tiên", prop: "priority" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.priority,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "priority", $$v)
                              },
                              expression: "form.priority",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.type !== "CLOTHING"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Phần trăm hoa hồng cấp 1 (%)" } },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }",
                              },
                            ],
                            model: {
                              value: _vm.form.refCommissionPercent,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "refCommissionPercent", $$v)
                              },
                              expression: "form.refCommissionPercent",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.type !== "CLOTHING"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Phần trăm hoa hồng cấp 2 (%)" } },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n            }",
                              },
                            ],
                            model: {
                              value: _vm.form.childRefCommissionPercent,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "childRefCommissionPercent",
                                  $$v
                                )
                              },
                              expression: "form.childRefCommissionPercent",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              !_vm.levelSelected
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Hình ảnh (Tỷ lệ: 1/1)",
                            prop: "thumbnail",
                          },
                        },
                        [
                          _c("ChooseFromMedia", {
                            attrs: {
                              height: "50px",
                              width: "50px",
                              "path-image": _vm.form.thumbnail,
                            },
                            on: { onChooseMedia: _vm.handleUploadSuccess },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }