"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var orderApi = exports.orderApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/order',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/order',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/update"),
      data: data,
      method: 'post'
    });
  },
  kioviet: function kioviet(id, data) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/kiotViet"),
      data: data,
      method: 'post'
    });
  },
  trackingModified: function trackingModified(id, params) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/trackingModification"),
      params: params
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/delete"),
      method: 'post'
    });
  },
  status: function status(id, data) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/status/update"),
      data: data,
      method: 'post'
    });
  }
};