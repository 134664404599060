var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    { attrs: { data: _vm.data, border: "", fit: "" } },
    [
      _c("el-table-column", { attrs: { label: "Tên sách", prop: "name" } }),
      _c("el-table-column", {
        attrs: { label: "Giá gốc", prop: "originPrice" },
      }),
      _c("el-table-column", {
        attrs: { label: "Giá sau KM", prop: "finalPrice" },
      }),
      _c("el-table-column", { attrs: { label: "Kích cỡ", prop: "size" } }),
      _c("el-table-column", { attrs: { label: "Số trang", prop: "page" } }),
      _c("el-table-column", { attrs: { label: "Mô tả", prop: "description" } }),
      _c("el-table-column", {
        attrs: { label: "Nhà xuất bản" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "el-select",
                  {
                    attrs: { disabled: "", placeholder: "" },
                    model: {
                      value: row.publisherId,
                      callback: function ($$v) {
                        _vm.$set(row, "publisherId", $$v)
                      },
                      expression: "row.publisherId",
                    },
                  },
                  _vm._l(_vm.publishers, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "Tác giả" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "el-select",
                  {
                    attrs: { disabled: "", placeholder: "" },
                    model: {
                      value: row.authorId,
                      callback: function ($$v) {
                        _vm.$set(row, "authorId", $$v)
                      },
                      expression: "row.authorId",
                    },
                  },
                  _vm._l(_vm.authors, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "Khối" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "el-select",
                  {
                    attrs: { disabled: "", placeholder: "" },
                    model: {
                      value: row.gradeId,
                      callback: function ($$v) {
                        _vm.$set(row, "gradeId", $$v)
                      },
                      expression: "row.gradeId",
                    },
                  },
                  _vm._l(_vm.grades, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "Hình thức" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "el-select",
                  {
                    attrs: { disabled: "", placeholder: "" },
                    model: {
                      value: row.coverId,
                      callback: function ($$v) {
                        _vm.$set(row, "coverId", $$v)
                      },
                      expression: "row.coverId",
                    },
                  },
                  _vm._l(_vm.covers, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "Danh mục" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _vm._v(" " + _vm._s(_vm.findCategories(row.categoryId)) + " "),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }