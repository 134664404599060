"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vueAutonumeric = _interopRequireDefault(require("vue-autonumeric"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = _vue.default.extend({
  components: {
    VueAutoNumeric: _vueAutonumeric.default
  },
  props: ["value", "options"],
  methods: {
    handleInput: function handleInput(value) {
      this.$emit("input", value);
    }
  }
});