var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _vm.user
      ? _c(
          "div",
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 10, xs: 24 } },
                  [_c("user-card")],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 14, xs: 24 } },
                  [
                    _c(
                      "el-card",
                      [
                        _c(
                          "el-tabs",
                          {
                            model: {
                              value: _vm.activeTab,
                              callback: function ($$v) {
                                _vm.activeTab = $$v
                              },
                              expression: "activeTab",
                            },
                          },
                          [
                            _c(
                              "el-tab-pane",
                              {
                                attrs: {
                                  label: "Đổi mật khẩu",
                                  name: "account",
                                },
                              },
                              [_c("account")],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }