var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "image-box", on: { click: _vm.handleChoose } },
    [
      _c("img", {
        staticClass: "image",
        attrs: { src: _vm.$baseUrlMedia + _vm.data.thumbnail, alt: "" },
      }),
      _c("div", { staticClass: "name-box" }, [
        _c("span", [_vm._v(_vm._s(_vm.data.name))]),
      ]),
      _vm.type == _vm.MediaType.Choose
        ? _c("div", { staticClass: "selected", class: _vm.handleSelected }, [
            _c("i", { staticClass: "el-icon-check" }),
          ])
        : _vm._e(),
      _vm.type == _vm.MediaType.Update
        ? _c("el-checkbox", {
            staticStyle: { position: "absolute", right: "10px", top: "10px" },
            attrs: { value: _vm.data.selected, label: "" },
            on: { change: _vm.handleSelect },
          })
        : _vm._e(),
      _vm.type == _vm.MediaType.Update
        ? _c(
            "div",
            {
              staticClass: "control-mask",
              on: {
                click: function ($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.handleSelect($event)
                },
              },
            },
            [
              _c("el-button", {
                staticStyle: { width: "40px", height: "40px" },
                attrs: { type: "primary", icon: "el-icon-connection" },
                on: { click: _vm.handleCopy },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }