"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _Category = _interopRequireDefault(require("./Category.vue"));
var _default = exports.default = {
  components: {
    Category: _Category.default
  },
  data: function data() {
    return {
      activeTab: "BOOK" // Tab mặc định
    };
  },
  methods: {
    handleTabClick: function handleTabClick(tab) {
      this.activeTab = tab.name; // Cập nhật tab hiện tại
    }
  }
};