"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reviewApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var reviewApi = exports.reviewApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/review',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/review',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/review/".concat(id, "/update"),
      data: data,
      method: 'post'
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/review/".concat(id, "/delete"),
      method: 'post'
    });
  }
};