var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { margin: "-20px" } },
    [_c("ContactBook", { attrs: { "school-id": _vm.school.id } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }