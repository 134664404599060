"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _newsMaccacaPlus = _interopRequireDefault(require("./news-maccaca-plus"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Maccaca-plus",
  components: {
    NewsMaccacaPlus: _newsMaccacaPlus.default
  },
  data: function data() {
    return {
      currentRole: "NewsMaccacaPlus",
      update: 0
    };
  },
  methods: {
    updateTable: function updateTable() {
      this.update += 1;
    }
  }
};