"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      data: {
        orderDetails: []
      },
      visible: false
    };
  },
  computed: {
    getTotalQuantity: function getTotalQuantity() {
      var sum = 0;
      this.data.orderDetails.forEach(function (e) {
        sum += e.quantity;
      });
      return sum;
    }
  },
  methods: {
    handleView: function handleView(data) {
      this.data = data;
      this.visible = true;
    }
  }
};