"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _objectSpread3 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _Summary = _interopRequireDefault(require("./Summary"));
var _SummaryBook = _interopRequireDefault(require("./SummaryBook"));
var _HistorySubmit = _interopRequireDefault(require("./HistorySubmit"));
var _SummarySchool = _interopRequireDefault(require("./SummarySchool"));
var _Export2Excel = require("@/vendor/Export2Excel");
var _SummaryGrade = _interopRequireDefault(require("./SummaryGrade.vue"));
var _PreOrder = _interopRequireDefault(require("./PreOrder.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Summary: _Summary.default,
    SummaryBook: _SummaryBook.default,
    HistorySubmit: _HistorySubmit.default,
    SummarySchool: _SummarySchool.default,
    SummaryGrade: _SummaryGrade.default,
    PreOrder: _PreOrder.default
  },
  data: function data() {
    return {
      visible: false,
      data: {},
      totalCommission: 0,
      commission: {},
      submitConvert: [],
      school: {},
      tabName: "general"
    };
  },
  methods: {
    handleExport: function handleExport() {
      var header = ["Sách", "Đơn giá", "SL", "Thành tiền", "SL nộp(Phòng)", "Hoa hồng(Phòng)", "SL nộp(C.ty)", "Hoa hồng(Cty)"];
      var total = {
        quantity: 0,
        quantityDivision: 0,
        divisionCommission: 0,
        quantityCompany: 0,
        companyCommission: 0,
        amount: 0
      };
      var data = [];
      this.submitConvert.forEach(function (element) {
        total.quantity += element.quantity;
        total.amount += element.quantity * element.finalPrice;
        total.quantityDivision += element.quantityDivision;
        total.divisionCommission += element.divisionCommission;
        total.quantityCompany += element.quantityCompany;
        total.companyCommission += element.companyCommission;
        data.push([element.name, element.finalPrice, element.quantity, element.finalPrice * element.quantity, element.quantityDivision, element.divisionCommission, element.quantityCompany, element.companyCommission]);
      });
      data.push(["Tổng", "", total.quantity, total.amount, total.quantityDivision, total.divisionCommission, total.quantityCompany, total.companyCommission]);
      (0, _Export2Excel.export_json_to_excel)({
        data: data,
        header: header,
        filename: "\u0110\u1EE3t \u0111\u1EB7t s\xE1ch - ".concat(this.school.name, " (").concat(this.school.addressCity.nameWithType, ")")
      });
    },
    onChangeTotalCommission: function onChangeTotalCommission(val) {
      this.commission = val;
    },
    convertSubmit: function convertSubmit() {
      var books = [];
      this.data.submits.forEach(function (e) {
        var quantityType = e.type == "COMPANY" ? "quantityCompany" : "quantityDivision";
        var commissionType = e.type == "COMPANY" ? "companyCommission" : "divisionCommission";
        e.schoolSubmitPreOrderDetails.forEach(function (element) {
          var book = books.find(function (e) {
            return e.id == element.book.id;
          });
          if (book) {
            book[quantityType] += element.quantity;
            book[commissionType] += element.commission;
            book.quantity += element.quantity;
          } else {
            books.push((0, _objectSpread3.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, quantityType, element.quantity), commissionType, element.commission), quantityType == "quantityCompany" ? "quantityDivision" : "quantityCompany", 0), commissionType == "companyCommission" ? "divisionCommission" : "companyCommission", 0), "quantity", element.quantity), element.book));
          }
        });
      });
      this.submitConvert = books;
    },
    handleDetail: function handleDetail(data, school) {
      this.visible = true;
      this.data = (0, _objectSpread3.default)({}, data);
      this.convertSubmit();
      this.school = school;
    }
  }
};