"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.customerApi = void 0;
exports.findAll = findAll;
exports.restore = exports.reset = exports.remove = void 0;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
var customerApi = exports.customerApi = {
  affiliateChildren: function affiliateChildren(id) {
    return (0, _request.default)({
      url: "/customer/".concat(id, "/affiliate/children")
    });
  },
  affiliateParent: function affiliateParent(id) {
    return (0, _request.default)({
      url: "/customer/".concat(id, "/affiliate/parent")
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/customer/".concat(id)
    });
  },
  findAll: findAll,
  block: function block(id) {
    return (0, _request.default)({
      url: "/customer/".concat(id, "/block"),
      method: "PUT"
    });
  },
  unblock: function unblock(id) {
    return (0, _request.default)({
      url: "/customer/".concat(id, "/unblock"),
      method: "PUT"
    });
  },
  update: update
};
var reset = exports.reset = function reset(id, data) {
  return (0, _request.default)({
    url: "/customer/".concat(id, "/update/password"),
    data: data,
    method: "post"
  });
};
function findAll(listQuery) {
  return (0, _request.default)({
    url: "/customer",
    method: "get",
    params: listQuery
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/customer",
    method: "post",
    data: data
  });
}
function update(customerId, data) {
  return (0, _request.default)({
    url: "/customer/".concat(customerId, "/update"),
    method: "post",
    data: data
  });
}
var remove = exports.remove = function remove(id) {
  return (0, _request.default)({
    url: "/customer/".concat(id, "/delete"),
    method: "post"
  });
};
var restore = exports.restore = function restore(id) {
  return (0, _request.default)({
    url: "/customer/".concat(id, "/restore"),
    method: "post"
  });
};