var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c("panel-group"),
      _c(
        "el-row",
        { attrs: { gutter: 32 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "chart-wrapper" }, [_c("OrderDay")], 1),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "chart-wrapper" }, [_c("OrderMonth")], 1),
          ]),
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("custom-tracking-chart")],
              1
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "chart-wrapper" }, [_c("Top10BookChart")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }