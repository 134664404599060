import { render, staticRenderFns } from "./index.vue?vue&type=template&id=591fe692"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=591fe692&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Admin\\Documents\\163hebec_web-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('591fe692')) {
      api.createRecord('591fe692', component.options)
    } else {
      api.reload('591fe692', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=591fe692", function () {
      api.rerender('591fe692', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/news/news-maccaca-plus/index.vue"
export default component.exports