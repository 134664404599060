"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.BannerType = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _ChooseFromMedia = _interopRequireDefault(require("@/components/Upload/ChooseFromMedia"));
var _bannerWeb = require("@/api/banner-web");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var BannerType = exports.BannerType = {
  News: "NEWS",
  Promotion: "PROMOTION"
};
var _default = exports.default = {
  components: {
    ChooseFromMedia: _ChooseFromMedia.default,
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      BannerType: BannerType,
      bannerType: "",
      rules: {
        title: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập tiêu đề"
        },
        phone: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập số điện thoại"
        },
        banner: {
          required: true,
          trigger: "submit",
          message: "Vui lòng upload banner"
        },
        body: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập mô tả"
        }
      },
      submitLoading: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      dialogLoading: false,
      formData: {},
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      news: [],
      promotions: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])),
  methods: {
    onChooseMedia: function onChooseMedia(path) {
      this.$set(this.formData, "thumbnail", path);
    },
    handleDelete: function handleDelete() {
      var _this = this;
      this.$confirm("Banner này sẽ bị ẩn, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        _this.loadingButton = true;
        _bannerWeb.bannerWebApi.delete(_this.formData.id).then(function () {
          _this.$message({
            type: "success",
            message: "Xoá thành công"
          });
          _this.dialogVisible = false;
          _this.$emit("create:success");
        }).finally(function () {
          return _this.loadingButton = false;
        });
      });
    },
    createData: function createData() {
      var _this2 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this2.submitLoading = true;
          var data = {
            bannerWeb: _this2.formData
          };
          _bannerWeb.bannerWebApi.create(data).then(function () {
            _this2.$message({
              type: "success",
              message: "Thêm banner thành công"
            });
            _this2.$emit("create:success");
            _this2.dialogVisible = false;
          }).finally(function () {
            _this2.submitLoading = false;
          });
        }
      });
    },
    updateData: function updateData() {
      var _this3 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this3.submitLoading = true;
          var data = {
            bannerWeb: _this3.formData
          };
          _bannerWeb.bannerWebApi.update(_this3.formData.id, data).then(function () {
            _this3.$message({
              type: "success",
              message: "Cập nhật banner thành công"
            });
            _this3.$emit("update:success");
            _this3.dialogVisible = false;
          }).finally(function () {
            _this3.submitLoading = false;
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;
      this.dialogVisible = true;
      this.dialogStatus = "update";
      this.bannerType = "";
      this.formData = (0, _objectSpread2.default)({}, row);
      this.$nextTick(function () {
        _this4.$refs["form"].clearValidate();
        _this4.$refs["editor"].setContent(_this4.formData.body);
      });
    },
    handleCreate: function handleCreate() {
      var _this5 = this;
      this.dialogVisible = true;
      this.dialogStatus = "create";
      this.bannerType = "";
      this.$nextTick(function () {
        _this5.$refs["form"].clearValidate();
        _this5.formData = {
          position: 0
        };
        _this5.$refs["editor"].setContent("");
      });
    },
    handleUploadSuccess: function handleUploadSuccess(res) {
      this.$set(this.formData, "thumbnail", res.data.path);
    }
  }
};