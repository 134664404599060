"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PaymentTypeTrans = exports.PaymentType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/163hebec_web-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var PaymentType;
(function (PaymentType) {
  PaymentType["Cash"] = "CASH";
  PaymentType["VNPAY"] = "VNPAY";
  PaymentType["TeacherPay"] = "TEACHER_PAY";
})(PaymentType || (exports.PaymentType = PaymentType = {}));
var PaymentTypeTrans = exports.PaymentTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, PaymentType.Cash, 'Tiền mặt'), PaymentType.VNPAY, 'VnPay');