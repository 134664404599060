var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.dialogStatus == "update" ? "Cập nhật banner" : "Thêm banner",
        visible: _vm.dialogVisible,
        width: "1200px",
        top: "15px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "80px",
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Vị trí hiển thị" } },
                        [
                          _c("input-money", {
                            model: {
                              value: _vm.formData.position,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "position", $$v)
                              },
                              expression: "formData.position",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Nút mở app học sinh" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: true },
                              model: {
                                value: _vm.formData.isOpenCustomerApp,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "isOpenCustomerApp",
                                    $$v
                                  )
                                },
                                expression: "formData.isOpenCustomerApp",
                              },
                            },
                            [_vm._v("Có")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: false },
                              model: {
                                value: _vm.formData.isOpenCustomerApp,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "isOpenCustomerApp",
                                    $$v
                                  )
                                },
                                expression: "formData.isOpenCustomerApp",
                              },
                            },
                            [_vm._v("Không")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Banner (Tỷ lệ 7/3)",
                                prop: "thumbnail",
                              },
                            },
                            [
                              _c("ChooseFromMedia", {
                                attrs: {
                                  height: "170px",
                                  width: "100%",
                                  "path-image": _vm.formData.thumbnail,
                                },
                                on: { onChooseMedia: _vm.onChooseMedia },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Mô tả", prop: "body" } },
                        [
                          _c("Tinymce", {
                            ref: "editor",
                            attrs: { width: "100%", height: 400 },
                            model: {
                              value: _vm.formData.body,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "body", $$v)
                              },
                              expression: "formData.body",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm.dialogStatus != "create"
            ? _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.handleDelete } },
                [_vm._v("Xoá")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.submitLoading, type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogStatus == "create"
                    ? _vm.createData()
                    : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }