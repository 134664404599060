var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "", stripe: "" } },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _c("el-table-column", {
            attrs: { label: "Loại", prop: "param" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.Params[row.param] || "Không xác định") +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Giá trị", prop: "value" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.param == "HEADER_PRINT_TICKET_IMAGE_LINK" ||
                    row.param == "FOOTER_PRINT_TICKET_IMAGE_LINK"
                      ? _c("img", {
                          attrs: {
                            width: "200",
                            src: _vm.$baseUrlMedia + row.value,
                            alt: "",
                          },
                        })
                      : _c("span", [_vm._v(" " + _vm._s(row.value) + " ")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "Ghi chú", prop: "note" } }),
          _c("el-table-column", {
            attrs: { label: "Hành động" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v(" Cập nhật ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "500px",
            title: _vm.dialogStatus == "edit" ? "Cập nhật" : "Thêm",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Loại", prop: "param" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { filterable: "", disabled: "" },
                              model: {
                                value: _vm.formData.param,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "param", $$v)
                                },
                                expression: "formData.param",
                              },
                            },
                            _vm._l(_vm.Params, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item, value: index },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Giá trị", prop: "value" } },
                        [
                          _vm.formData.param == "CONTACT_BOOK_PRICE"
                            ? _c("input-money", {
                                model: {
                                  value: _vm.formData.value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "value", $$v)
                                  },
                                  expression: "formData.value",
                                },
                              })
                            : _vm.formData.param ==
                                "HEADER_PRINT_TICKET_IMAGE_LINK" ||
                              _vm.formData.param ==
                                "FOOTER_PRINT_TICKET_IMAGE_LINK"
                            ? _c("single-image", {
                                staticStyle: { "text-align": "center" },
                                attrs: { "path-image": _vm.formData.value },
                                on: {
                                  "upload:success": _vm.handleUploadSuccess,
                                },
                              })
                            : _c("el-input", {
                                attrs: {
                                  type:
                                    _vm.formData.param == "NOTE_PRINT_TICKET"
                                      ? "textarea"
                                      : "text",
                                },
                                model: {
                                  value: _vm.formData.value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "value", $$v)
                                  },
                                  expression: "formData.value",
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Ghi chú", prop: "note" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea" },
                            model: {
                              value: _vm.formData.note,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "note", $$v)
                              },
                              expression: "formData.note",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: { click: _vm.editData },
                },
                [_vm._v("Đồng ý")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }